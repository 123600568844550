import React from 'react'
import { convert } from 'html-to-text'

interface ProductSchemaProps {
  productName: string
  productImage: string
  productDescription: string
  productPrice: string
  productCurrency: string
}

export const ProductSchema: React.FC<ProductSchemaProps> = ({
  productName,
  productImage,
  productDescription,
  productPrice,
  productCurrency,
}) => {
  if (!productName || !productPrice || !productCurrency) {
    return null
  }

  const schemaJsonObj: any = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: productName,
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      price: productPrice,
      priceCurrency: productCurrency.toUpperCase(),
    },
  }

  if (productImage) {
    schemaJsonObj.image = productImage
  }

  if (productDescription) {
    schemaJsonObj.description = convert(productDescription, {
      selectors: [
        { selector: 'img', format: 'skip' },
        { selector: 'a', options: { ignoreHref: true } },
      ],
    })
  }

  return (
    <script
      key='schema-jsonld'
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaJsonObj) }}
    />
  )
}
