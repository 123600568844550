import React, { Fragment, useEffect, useState, useRef } from 'react'
import classNames from 'classnames'

import NoData from 'ui/no-data/NoData'
import Input from 'ui-old/InputNext'
import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'
import { RadioField } from '@elo-kit/components/form/radio-field/RadioField'
import { EloRadioButton } from '@elo-ui/components/elo-radio-button'

import {
  injectStripeScript,
  createStripeClient,
  createStripeElements,
  createStripeP24,
  createStripeIdeal,
  createStripeKlarna,
  createStripeCard,
  createStripeSepa,
  getKlarnaFormSettings,
  allowedToUseStripe,
} from '@elo-kit/utils/stripe.utils'
import { createImg, getPaymentMethodIcon, getPubKey } from 'utils/paymentSettingShared.utils'
import { isEqual } from 'utils/lodash.utils'
import { getFraudSessionIdentifier, insertFraudPaypalSnippet } from 'utils/ordersShared.utils'
import { capitalize } from 'utils/nameStyle.utils'

import {
  PAYMENT_FORMS,
  PAYMENT_PROVIDERS,
  PAYMENT_PROVIDERS_LIST,
  P24_ROOT_ID,
  IDEAL_ROOT_ID,
  PAYMENT_LOGOS_LIST_HEIGHT,
  KLARNA_ROOT_ID,
  PAYER_FORM_TYPE,
} from 'constants/paymentSettingShared.constants'
import {
  CURRENCY_COUNTRY_MAP,
  getPaymentFormsLabels,
  KLARNA_COUNTRIES,
} from '@elo-kit/constants/paymentSetting.constants'
import { Stripe, StripeElement, StripeElementsOptionsMode } from 'shop/types/stripe'
import { DEFAULT_CURRENCY } from 'constants/currencies.constants'
import { KLARNA_KEY, KLARNA_SUBSCRIPTIONS } from 'constants/options.constants'

import { EloInfoMessage } from '@elo-ui/components/elo-info-message'
import { EloWarningCircleIcon } from '@elo-ui/components/icons/regular'
import { EloCard } from '@elo-ui/components/elo-card'
import { EloAccordion } from '@elo-ui/components/elo-accordion'

import { OrdersStore } from 'shop/stores/orders.store'
import { CurrenciesStore } from 'shop/stores/currencies.store'
import { DealsStore } from 'shop/stores/deals.store'
import { useShopStores } from 'shop/hooks/use-store'
import { PAYMENT_PLANS } from 'constants/pricingPlans.constants'
import { CreditCard, Card } from './CreditCard'
import { PaymentMethodSlider } from './payment-method-slider/PaymentMethodSlider'

interface PurchaseMethodsProps {
  activeMethod: string
  allowedMethods: string[]
  shouldResetIntent?: boolean
  handleIntentReset?: () => void
  stripePubKey: string
  elopageConnectPubKey?: string
  setStripeCardValidity?: (value: boolean) => void
  forceDirty: boolean
  forSubscription: boolean
  showBic?: boolean
  providers: Record<string, string>
  updatePaymethodsData: (data: { iban: string; creditCard: any; form: string; valid: boolean }) => void
  setBuyBtnDisabling?: (value: boolean) => void
  setStripeP24?: (value: StripeElement) => void
  setStripeIdeal?: (value: StripeElement) => void
  setStripeElements?: (value: StripeElement | string) => StripeElement | string
  setStripeClient: (value: Stripe) => void
  toggleP24BankSelection?: (value: boolean) => void
  setStripeCard: (value: () => void) => void
  sellerStore: any //todo
  pastDue?: string
  paypalProvider?: string
  sepaImmediateAccess?: boolean
  pricingPlanSofortSepa?: boolean
  pricingPlanIdealSepa?: boolean
  isOrderWithSofortSepa?: string
  isManagePage?: boolean
  stripeCardValid?: boolean
  setFraudSessionIdentifier?: (value: string) => void
  preferredPaymentMethods?: string[]
  visualSeparationEnabled?: boolean
  themeStore?: any
  paymentStore?: any
  ordersStore?: OrdersStore
  currenciesStore?: CurrenciesStore
  dealsStore?: DealsStore
  planId?: number
  cabinetPreview?: string
  onMethodChange?: (data: { activeMethod: string; allowedMethods: string[] }) => void
  isDealsPage?: boolean
  isCheckoutPage?: boolean
  googleAvailability?: {
    googlePay: boolean
  }
  appleAvailability?: {
    applePay: boolean
  }
}

export const PurchaseMethods: React.FC<PurchaseMethodsProps> = ({
  activeMethod: activeMethodProp,
  allowedMethods,
  shouldResetIntent,
  handleIntentReset,
  setStripeCardValidity,
  forceDirty,
  forSubscription,
  showBic,
  providers,
  providers: { cardProvider, sofortProvider, sepaProvider },
  updatePaymethodsData,
  setBuyBtnDisabling = () => {},
  setStripeP24,
  setStripeIdeal,
  setStripeElements,
  setStripeClient,
  toggleP24BankSelection,
  setStripeCard,
  ordersStore,
  currenciesStore,
  sellerStore: {
    item: { stripeSofortSepa, elopageConnectSofortSepa, paypalMerchantId },
    isAppActive,
  },
  dealsStore,
  pastDue,
  paypalProvider,
  sepaImmediateAccess,
  pricingPlanSofortSepa,
  pricingPlanIdealSepa,
  isOrderWithSofortSepa,
  isManagePage,
  isDealsPage,
  setFraudSessionIdentifier,
  preferredPaymentMethods,
  visualSeparationEnabled,
  themeStore,
  cabinetPreview,
  onMethodChange = () => {},
  isCheckoutPage,
  googleAvailability,
  appleAvailability,
  ...props
}) => {
  const [iban, setIban] = useState<string>('')
  const [activeMethod, setActiveMethod] = useState<string>(activeMethodProp || allowedMethods[0])
  const [creditCard, setCreditCard] = useState<Card>({})
  const [stripeInited, setStripeInited] = useState<boolean>(false)
  const [p24Loading, setP24Loading] = useState<boolean>(false)
  const [idealLoading, setIdealLoading] = useState<boolean>(false)
  const [klarnaLoading, setKlarnaLoading] = useState<boolean>(false)
  const [sepaLoading, setSepaLoading] = useState<boolean>(false)
  const [p24Error, setP24Error] = useState<string>('')
  const [idealError, setIdealError] = useState<string>('')
  const [klarnaError, setKlarnaError] = useState<string>('')
  const [stripeCardValid, setStripeCardValid] = useState<boolean>(false)
  const [ibanIsValid, setIbanIsValid] = useState<boolean>(false)

  const stripeCard = useRef(null)
  const stripeP24 = useRef(null)
  const stripeIdeal = useRef(null)
  const stripeKlarna = useRef(null)
  const stripeSepa = useRef(null)

  const stripeClient = useRef(null)
  const stripeElements = useRef(null)
  const stripeCardError = useRef(null)

  const { paymentStore, experimentsStore } = useShopStores()

  const horizontalPaymentMethodExperiment = experimentsStore.useExperiment('horizontal_payment_methods_selector')
  const newMethodWithAccordion = horizontalPaymentMethodExperiment.get('newPaymentMethodSelector') === 'with_accordion'
  const newMethodWithoutAccordion =
    horizontalPaymentMethodExperiment.get('newPaymentMethodSelector') === 'without_accordion'

  const isNewPaymentMethodsUI = newMethodWithAccordion || newMethodWithoutAccordion

  const isStripeSepaProvider =
    sepaProvider === PAYMENT_PROVIDERS.stripe || sepaProvider === PAYMENT_PROVIDERS.elopageConnect

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevAllowedMethods = usePrevious(allowedMethods)

  const insertFraudSnippet = () => {
    if (activeMethod === PAYMENT_FORMS.paypal) {
      const fraudSessionIdentifier = getFraudSessionIdentifier({
        paypalMerchantId: paypalMerchantId,
        paypalProvider: paypalProvider,
        paymentForm: activeMethod,
      })

      if (fraudSessionIdentifier) {
        insertFraudPaypalSnippet(fraudSessionIdentifier, paypalMerchantId)
        setFraudSessionIdentifier(fraudSessionIdentifier)
      }
    } else if (setFraudSessionIdentifier) {
      setFraudSessionIdentifier(null)
    }
  }

  useEffect(() => {
    if (!cabinetPreview) {
      injectStripeScript(() => {
        formShouldBeUpdated()
      })
    }
  }, [])

  useEffect(() => {
    insertFraudSnippet()
  }, [activeMethod])

  useEffect(() => {
    if (activeMethod === (activeMethodProp || allowedMethods[0])) {
      return
    }
    onMethodChange({ activeMethod, allowedMethods: paymentMethodsToShow })
  }, [activeMethod])

  useEffect(() => {
    if (prevAllowedMethods && !isEqual(allowedMethods, prevAllowedMethods)) {
      resetActiveMethod()
    }
  }, [allowedMethods])

  useEffect(() => {
    setActiveMethod(activeMethodProp || preferredPaymentMethods?.['0'] || allowedMethods[0])
  }, [JSON.stringify(preferredPaymentMethods)])

  useEffect(() => {
    formShouldBeUpdated()
  }, [
    props.stripePubKey,
    props.elopageConnectPubKey,
    activeMethod,
    iban,
    creditCard,
    stripeCardValid,
    ibanIsValid,
    paymentStore?.buildedOrderLoading,
  ])

  useEffect(() => {
    if (shouldResetIntent) {
      stripeCard.current.clear()

      /* Reset stripe card validity */
      setStripeCardValidity(false)

      handleIntentReset()
    }
  }, [shouldResetIntent])

  const isKlarnaAvailable = (currency: string) => {
    if (!isAppActive) {
      return false
    }
    let activeCountry

    if (dealsStore) {
      activeCountry = dealsStore.item?.payerData?.userProfileAttributes?.countryCode
    } else if (isManagePage) {
      activeCountry = ordersStore.data?.payerData?.userProfileAttributes?.countryCode
    } else {
      activeCountry = paymentStore?.payerCountry
    }

    return (
      isAppActive(KLARNA_KEY) &&
      KLARNA_COUNTRIES.includes((activeCountry || 'DE').toUpperCase()) &&
      CURRENCY_COUNTRY_MAP[currency.toUpperCase()]?.includes(activeCountry)
    )
  }

  const isKlarnaSubscriptionsAvailable = (currency: string) =>
    isKlarnaAvailable(currency) && isAppActive(KLARNA_SUBSCRIPTIONS)

  const setNotKlarnaActiveMethod = () => {
    setActiveMethod(allowedMethods.find((method) => method !== PAYMENT_FORMS.klarna) || null)
  }

  useEffect(() => {
    if (isActive(PAYMENT_FORMS.klarna)) {
      if (!isKlarnaAvailable(activeCurrency)) {
        setNotKlarnaActiveMethod()
      } else {
        setActiveMethod(PAYMENT_FORMS.klarna)
      }
    }
  }, [ordersStore.data?.payerData?.userProfileAttributes?.countryCode, paymentStore?.payerCountry])

  const resetActiveMethod = () => {
    setActiveMethod(allowedMethods[0])
  }

  const formShouldBeUpdated = async () => {
    updateParentState()

    if (!isStripeSepaProvider && isActive(PAYMENT_FORMS.sepa) && !ibanIsValid) {
      setBuyBtnDisabling(true)
    }

    const pubKey = props[getPubKey(activeMethod, providers)]

    const isStripeCard =
      (isActive(PAYMENT_FORMS.card) && cardProvider === PAYMENT_PROVIDERS.stripe) ||
      cardProvider === PAYMENT_PROVIDERS.elopageConnect
    const isStripeP24 = isActive(PAYMENT_FORMS.p24) && allowedMethods.indexOf(PAYMENT_FORMS.p24) >= 0
    const isStripeIdeal = isActive(PAYMENT_FORMS.ideal) && allowedMethods.indexOf(PAYMENT_FORMS.ideal) >= 0
    const isStripeKlarna = isActive(PAYMENT_FORMS.klarna) && allowedMethods.indexOf(PAYMENT_FORMS.klarna) >= 0
    const applePayIsAllowed = allowedMethods.indexOf(PAYMENT_FORMS.applePay) >= 0
    const googlePayIsAllowed = allowedMethods.indexOf(PAYMENT_FORMS.googlePay) >= 0
    const isStripeApplePay = isActive(PAYMENT_FORMS.applePay) && applePayIsAllowed
    const isStripeGooglePay = isActive(PAYMENT_FORMS.googlePay) && googlePayIsAllowed
    const isStripeSepa =
      isActive(PAYMENT_FORMS.sepa) && isStripeSepaProvider && allowedMethods.indexOf(PAYMENT_FORMS.sepa) >= 0
    const shouldManageStripeScript =
      pubKey &&
      (isStripeCard ||
        isActive(PAYMENT_FORMS.sofort) ||
        isStripeP24 ||
        isStripeIdeal ||
        isStripeKlarna ||
        isStripeApplePay ||
        isStripeGooglePay ||
        isStripeSepa) &&
      allowedToUseStripe() &&
      (!stripeInited || isStripeKlarna)
    if (shouldManageStripeScript) {
      await manageStripeMethods()
    }
  }

  const updateParentState = () => {
    updatePaymethodsData({
      iban,
      creditCard,
      form: activeMethod,
      valid: isCmpValid(),
    })
  }

  const selectPaymentMethod = (activateMethod) => {
    if (activeMethod === activateMethod) {
      return
    }

    /* Reset buy button state */
    setBuyBtnDisabling(false)

    setActiveMethod(activateMethod)
    setP24Error('')
    setIdealError('')
    setKlarnaError('')
    setP24Loading(false)
    setStripeInited(false)
  }

  const handleIbanInput = (iban) => setIban(iban)

  const handleIbanValid = (ibanIsValid) => {
    setIbanIsValid(ibanIsValid)
    setBuyBtnDisabling(!ibanIsValid)
  }

  const handleCreditCard = (creditCard) => setCreditCard(creditCard)

  const isActive = (method) => method === activeMethod

  const isCmpValid = () => {
    const hasPaymentMethod =
      [
        PAYMENT_FORMS.bankWire,
        PAYMENT_FORMS.sofort,
        PAYMENT_FORMS.paypal,
        PAYMENT_FORMS.payLater,
        PAYMENT_FORMS.applePay,
        PAYMENT_FORMS.googlePay,
        PAYMENT_FORMS.ideal,
        PAYMENT_FORMS.klarna,
        PAYMENT_FORMS.sepa,
      ].indexOf(activeMethod) >= 0
    const cardIsValid = activeMethod === PAYMENT_FORMS.card && isCardValid()
    const sepaOldIsValid = activeMethod === PAYMENT_FORMS.sepa && iban && ibanIsValid && (!forSubscription || !showBic)
    const sepaNewIsValid = activeMethod === PAYMENT_FORMS.sepa && !sepaLoading
    const sepaIsValid = isStripeSepaProvider ? sepaNewIsValid : sepaOldIsValid
    const p24IsValid = activeMethod === PAYMENT_FORMS.p24 && !p24Error && !p24Loading
    const idealValid = activeMethod === PAYMENT_FORMS.ideal && !idealError && !idealLoading
    const klarnaValid = activeMethod === PAYMENT_FORMS.klarna && !klarnaError && !klarnaLoading
    return hasPaymentMethod || cardIsValid || sepaIsValid || p24IsValid || idealValid || klarnaValid || false
  }
  let activeCurrency

  if (isDealsPage) {
    activeCurrency = dealsStore?.getCurrency()
  } else if (isManagePage) {
    activeCurrency = ordersStore?.getCurrency()
  } else {
    activeCurrency = paymentStore?.currency
  }

  const isCardValid = () => {
    const Lw3dsIsValid = cardProvider === PAYMENT_PROVIDERS.lemonWay
    const cardByProviderIsValid =
      cardProvider === PAYMENT_PROVIDERS.stripe || cardProvider === PAYMENT_PROVIDERS.elopageConnect
        ? stripeCardValid
        : creditCard.isValid
    return Lw3dsIsValid || cardByProviderIsValid
  }

  const getSepaStripeElementPayload = (): StripeElementsOptionsMode => {
    const emptyValue: StripeElementsOptionsMode = {
      mode: 'setup',
      currency: DEFAULT_CURRENCY,
      paymentMethodCreation: 'manual',
      paymentMethodTypes: ['sepa_debit'],
      setupFutureUsage: 'off_session',
    }

    if (isDealsPage) {
      return (dealsStore?.getSepaStripeElementPayload && dealsStore.getSepaStripeElementPayload()) || emptyValue
    } else if (isManagePage) {
      return (ordersStore?.getSepaStripeElementPayload && ordersStore.getSepaStripeElementPayload()) || emptyValue
    } else {
      return (paymentStore?.getSepaStripeElementPayload && paymentStore.getSepaStripeElementPayload()) || emptyValue
    }
  }

  const getKlarnaStripeElementPayload = (): StripeElementsOptionsMode => {
    const emptyValue: StripeElementsOptionsMode = {
      mode: 'payment',
      paymentMethodTypes: [],
      currency: '',
      amount: 0,
    }
    if (isDealsPage) {
      return (dealsStore?.getKlarnaStripeElementPayload && dealsStore.getKlarnaStripeElementPayload()) || emptyValue
    } else if (isManagePage) {
      return (ordersStore?.getKlarnaStripeElementPayload && ordersStore.getKlarnaStripeElementPayload()) || emptyValue
    } else {
      return (paymentStore?.getKlarnaStripeElementPayload && paymentStore.getKlarnaStripeElementPayload()) || emptyValue
    }
  }

  const manageStripeMethods = async () => {
    const pubKey = props[getPubKey(activeMethod, providers)]
    setStripeInited(true)

    /* Create a Stripe client instance locally & payment store */
    stripeClient.current = await createStripeClient(pubKey)

    if (setStripeClient) {
      setStripeClient(stripeClient.current)
    }

    if (isActive(PAYMENT_FORMS.klarna)) {
      stripeElements.current = createStripeElements(stripeClient.current, getKlarnaStripeElementPayload())
    } else if (isActive(PAYMENT_FORMS.sepa)) {
      stripeElements.current = createStripeElements(stripeClient.current, getSepaStripeElementPayload())
    } else {
      stripeElements.current = createStripeElements(stripeClient.current)
    }

    // Create an instance of Elements.

    if (isActive(PAYMENT_FORMS.card)) {
      // Create an instance of the card Element.
      stripeCard.current = createStripeCard(stripeElements.current, isNewPaymentMethodsUI)

      if (stripeCard.current) {
        // Add an instance of the card Element into the `card-element` <div>.
        stripeCard.current.mount('#card-element')

        // Handle real-time validation errors from the card Element.
        stripeCardError.current = document.getElementById('card-errors')

        stripeCard.current.on('ready', () => {
          stripeCardError.current.classList.remove('lmask')
        })

        stripeCard.current.addEventListener('change', ({ complete, error }) => {
          if (error) {
            stripeCardError.current.textContent = error.message
            setStripeCardValidity && setStripeCardValidity(false)
            setStripeCardValid(false)
          } else {
            stripeCardError.current.textContent = ''
            if (complete) {
              setStripeCardValidity && setStripeCardValidity(true)
              setStripeCard && setStripeCard(stripeCard.current)
              setStripeCardValid(true)
            }
          }
        })
      }
    }

    if (isActive(PAYMENT_FORMS.p24)) {
      /* Disable buy button: payment page (from store), change payment method - local function */
      setBuyBtnDisabling(true)

      setP24Loading(true)

      /* Configure StripeP24 instance locally & payment store */
      stripeP24.current = createStripeP24(stripeElements.current, isNewPaymentMethodsUI)

      if (stripeP24.current) {
        if (setStripeP24) {
          setStripeP24(stripeP24.current)
        }

        stripeP24.current.mount(`#${P24_ROOT_ID}`)

        stripeP24.current.on('ready', () => {
          setP24Loading(false)
        })

        stripeP24.current.on('change', ({ complete, error }) => {
          if (error) {
            setP24Error(error.message)
          } else {
            setP24Error('')

            if (complete) {
              /* Enable buy button */
              setBuyBtnDisabling(false)

              if (toggleP24BankSelection) {
                toggleP24BankSelection(true)
              }
            }
          }
        })
      }
    }

    if (isActive(PAYMENT_FORMS.ideal)) {
      /* Disable buy button: payment page (from store), change paymenth method - local function */
      setBuyBtnDisabling(true)

      setIdealLoading(true)

      /* Configure Ideal instace localy & payment store */
      stripeIdeal.current = createStripeIdeal(stripeElements.current, isNewPaymentMethodsUI)

      if (stripeIdeal.current) {
        if (setStripeIdeal) {
          setStripeIdeal(stripeIdeal.current)
        }

        stripeIdeal.current.mount(`#${IDEAL_ROOT_ID}`)

        stripeIdeal.current.on('ready', () => {
          setIdealLoading(false)
        })

        stripeIdeal.current.on('change', ({ complete, error }) => {
          if (error) {
            setIdealError(error.message)
          } else {
            setIdealError('')

            if (complete) {
              /* Enable buy button */
              setBuyBtnDisabling(false)
            }
          }
        })
      }
    }

    if (isActive(PAYMENT_FORMS.klarna)) {
      /* Disable buy button: payment page (from store), change paymenth method - local function */
      setBuyBtnDisabling(true)

      setKlarnaLoading(true)

      if (setStripeElements) {
        setStripeElements(stripeElements.current)
      }
      /* Configure Ideal instace localy & payment store */

      stripeKlarna.current = createStripeKlarna(stripeElements.current, getKlarnaFormSettings())

      if (stripeKlarna.current) {
        stripeKlarna.current.mount(`#${KLARNA_ROOT_ID}`)

        stripeKlarna.current.on('ready', () => {
          setKlarnaLoading(false)
        })

        stripeKlarna.current.on('loaderror', ({ error: { message } }) => {
          if (message) {
            setKlarnaError(message)
            setKlarnaLoading(false)
          }
        })

        stripeKlarna.current.on('change', ({ complete, error }) => {
          if (error) {
            setKlarnaError(error.message)
          } else {
            setKlarnaError('')

            if (complete) {
              /* Enable buy button */
              setBuyBtnDisabling(false)
            } else {
              setBuyBtnDisabling(true)
            }
          }
        })
      }
    }

    if (isActive(PAYMENT_FORMS.sepa)) {
      /* Disable buy button: payment page (from store), change paymenth method - local function */
      setBuyBtnDisabling(true)

      setSepaLoading(true)

      if (setStripeElements) {
        setStripeElements(stripeElements.current)
      }

      stripeSepa.current = createStripeSepa(stripeElements.current, {
        fields: {
          billingDetails: 'never',
        },
      })

      if (stripeSepa.current) {
        stripeSepa.current.mount('#sepa-debit-element')

        stripeSepa.current.on('ready', () => {
          setSepaLoading(false)
          setBuyBtnDisabling(false)
        })
      }
    }
  }
  let pricingPlanForm = null

  if (isDealsPage) {
    pricingPlanForm = dealsStore?.item.pricingPlan.form
  } else if (isManagePage) {
    pricingPlanForm = ordersStore?.data?.periodType
  } else {
    pricingPlanForm = paymentStore?.store?.activePlan?.form
  }

  const isSubscription = [PAYMENT_PLANS.subscription, PAYMENT_PLANS.limitedSubscription].includes(pricingPlanForm)
  const isOneTime = pricingPlanForm === PAYMENT_PLANS.oneTime

  const getPaymentMethodsToShow = () => {
    const methods = allowedMethods.filter(
      (method) =>
        ((method !== PAYMENT_FORMS.applePay || activeMethod === PAYMENT_FORMS.applePay || appleAvailability.applePay) &&
          (method !== PAYMENT_FORMS.googlePay ||
            activeMethod === PAYMENT_FORMS.googlePay ||
            googleAvailability?.googlePay) &&
          method !== PAYMENT_FORMS.klarna) ||
        (method === PAYMENT_FORMS.klarna && isKlarnaSubscriptionsAvailable(activeCurrency) && isSubscription) ||
        (method === PAYMENT_FORMS.klarna && isKlarnaAvailable(activeCurrency) && isOneTime)
    )

    if (paymentStore?.setPaymentMethodsAvailable) {
      paymentStore.setPaymentMethodsAvailable(methods)
    }

    return methods
  }

  const activeMethodBody = () => {
    const [isCardProviderLemonway, isCardProviderMangopay, isCardProviderElopageConnect, isCardProviderStripe] =
      PAYMENT_PROVIDERS_LIST.map((name) => cardProvider === name)
    const isSofortProviderStripe = sofortProvider === PAYMENT_PROVIDERS.stripe
    const isSofortProviderElopageConnect = sofortProvider === PAYMENT_PROVIDERS.elopageConnect
    const isSepaProviderStripe = sepaProvider === PAYMENT_PROVIDERS.elopageConnect
    const isSepaProviderElopageConnect = sepaProvider === PAYMENT_PROVIDERS.elopageConnect

    const sepaDescription =
      isSepaProviderStripe || isSepaProviderElopageConnect
        ? I18n.t('react.shared.payment_methods.sepa_terms_stripe')
        : ''
    const showStripeSofortMessage =
      isOrderWithSofortSepa ||
      ((isSofortProviderStripe || isSofortProviderElopageConnect) &&
        (stripeSofortSepa || elopageConnectSofortSepa) &&
        pricingPlanSofortSepa)

    const sofortInfoMessage = showStripeSofortMessage
      ? I18n.t('react.shared.payment_methods.stripe_sofort_sepa_info')
      : I18n.t('react.shared.payment_methods.sofort_info')

    const paymentMethodSelectorClasses = classNames({
      'old-payment-method-selector': !isNewPaymentMethodsUI,
      'payment-method-selector': isNewPaymentMethodsUI,
    })

    const payInfoClasses = classNames({
      'pay-info': !isNewPaymentMethodsUI,
      'new-pay-info': isNewPaymentMethodsUI,
    })

    const helpInfoClasses = classNames({
      'help-info': !isNewPaymentMethodsUI,
      'new-help-info': isNewPaymentMethodsUI,
    })

    const cardClasses = classNames({
      'new-card-block': isNewPaymentMethodsUI,
    })

    switch (activeMethod) {
      case PAYMENT_FORMS.card:
        return (
          <Fragment>
            {(isCardProviderStripe || isCardProviderElopageConnect) && (
              <Fragment>
                <div className={cardClasses} id='card-element' />
                <div id='card-errors' className='lmask' />
              </Fragment>
            )}
            {isCardProviderLemonway && (
              <CreditCard
                forceDirty={forceDirty}
                updateCreditCardData={handleCreditCard}
                isNewPaymentMethodsUI={isNewPaymentMethodsUI}
              />
            )}
            {isCardProviderMangopay && (
              <CreditCard
                forceDirty={forceDirty}
                updateCreditCardData={handleCreditCard}
                isNewPaymentMethodsUI={isNewPaymentMethodsUI}
              />
            )}
            {isManagePage && (
              <div className='credit-card-info'>{I18n.t('react.shared.payment_methods.credit_card_info')}</div>
            )}
          </Fragment>
        )
      case PAYMENT_FORMS.paypal:
        return (
          <Fragment>
            <div className={payInfoClasses}>{I18n.t('react.shared.payment_methods.paypal_info')}</div>
            {forSubscription && (
              <div className={helpInfoClasses}>
                {paypalProvider !== 'paypal_nvp'
                  ? I18n.t('react.shared.payment_methods.not_auto_chargable')
                  : I18n.t('react.shared.payment_methods.auto_chargable')}
              </div>
            )}
          </Fragment>
        )
      case PAYMENT_FORMS.sofort:
        return (
          <Fragment>
            <div className='pay-info'>{sofortInfoMessage}</div>
            {showStripeSofortMessage && (
              <div
                className='help-info help-info--without-separator'
                dangerouslySetInnerHTML={{
                  __html: I18n.t('react.shared.payment_methods.stripe_sofort_sepa_help_text'),
                }}
              />
            )}
            {!showStripeSofortMessage && forSubscription && (
              <div className='help-info'>{I18n.t('react.shared.payment_methods.not_auto_chargable')}</div>
            )}
          </Fragment>
        )
      case PAYMENT_FORMS.bankWire:
        return (
          <Fragment>
            <div className='pay-info'>{I18n.t('react.shared.payment_methods.bank_wire_info')}</div>
            {forSubscription && (
              <div className='help-info'>{I18n.t('react.shared.payment_methods.not_auto_chargable')}</div>
            )}
          </Fragment>
        )
      case PAYMENT_FORMS.sepa:
        return isStripeSepaProvider ? (
          <>
            <div id='sepa-debit-element' />
            {sepaLoading && <div className='lmask' />}
            <div id='sepa-error-message' />
          </>
        ) : (
          <>
            <div className='payment-method-label'>{I18n.t('react.shared.payment_methods.pay_with_sepa_label')}</div>
            <Input
              class='form-control new-payment-method-input'
              placeholder='IBAN'
              forceDirty={forceDirty}
              validations={['required', 'iban']}
              handleInput={handleIbanInput}
              handleValid={handleIbanValid}
            />
            <div className='new-help-info new-help-info--without-separator'>{sepaDescription}</div>
          </>
        )
      case PAYMENT_FORMS.payLater:
        return (
          <Fragment>
            <div className={payInfoClasses}>
              {I18n.t('react.shared.payment_methods.pay_later_info', { date: pastDue || 14 })}
            </div>
            {forSubscription && (
              <div className={helpInfoClasses}>{I18n.t('react.shared.payment_methods.not_auto_chargable')}</div>
            )}
          </Fragment>
        )
      case PAYMENT_FORMS.p24: {
        return (
          <Fragment>
            {isNewPaymentMethodsUI && (
              <div className='payment-method-label'>{I18n.t('react.shared.payment_methods.select_your_bank')}</div>
            )}
            <div className={paymentMethodSelectorClasses} id={P24_ROOT_ID} />
            {p24Loading && <div className='lmask' />}
            {p24Error && <div>{p24Error}</div>}
          </Fragment>
        )
      }
      case PAYMENT_FORMS.ideal: {
        return (
          <Fragment>
            {isNewPaymentMethodsUI && (
              <div className='payment-method-label'>{I18n.t('react.shared.payment_methods.select_your_bank')}</div>
            )}
            <div className={paymentMethodSelectorClasses} id={IDEAL_ROOT_ID} />
            {pricingPlanIdealSepa && (
              <div
                className='help-info help-info--without-separator'
                dangerouslySetInnerHTML={{
                  __html: I18n.t('react.shared.payment_methods.ideal_sepa_help_text'),
                }}
              />
            )}
            {idealLoading && <div className='lmask' />}
            {idealError && <div>{idealError}</div>}
          </Fragment>
        )
      }

      case PAYMENT_FORMS.klarna: {
        return (
          <Fragment>
            <div id={KLARNA_ROOT_ID} />
            {klarnaLoading && <LoadingMask />}
            {klarnaError && (
              <div>
                <EloInfoMessage icon={<EloWarningCircleIcon />} size='small' status='error'>
                  {klarnaError}
                </EloInfoMessage>
              </div>
            )}
          </Fragment>
        )
      }

      case PAYMENT_FORMS.applePay: {
        return (
          <Fragment>
            <div className='pay-info'>
              {appleAvailability.applePay
                ? I18n.t('react.shared.payment_methods.apple_pay_info')
                : I18n.t('react.shared.payment_methods.apple_pay_availability_info')}
            </div>
          </Fragment>
        )
      }

      case PAYMENT_FORMS.googlePay: {
        return (
          <Fragment>
            <div className='pay-info'>
              {googleAvailability?.googlePay
                ? I18n.t('react.shared.payment_methods.google_pay_info')
                : I18n.t('react.shared.payment_methods.google_pay_availability_info')}
            </div>
          </Fragment>
        )
      }

      default:
        return
    }
  }

  const paymentMethodsToShow = getPaymentMethodsToShow()

  const isUnderMethodExperiment = newMethodWithAccordion || newMethodWithoutAccordion

  const scrollWrapperRef = useRef(null)

  if (isCheckoutPage && isUnderMethodExperiment) {
    const preferredOrFirstPaymentMethod = paymentMethodsToShow[0]
    const paymentMethodsWithoutFirst = paymentMethodsToShow
      .filter((method) => method !== preferredOrFirstPaymentMethod)
      .slice(0, 3)
    const restPaymentMethods = paymentMethodsToShow.filter(
      (method) => method !== preferredOrFirstPaymentMethod && !paymentMethodsWithoutFirst.includes(method)
    )

    const color = themeStore && themeStore?.ppTemplate?.theme?.paymentPageColor

    const isMethodWithoutInfo = (method) =>
      [PAYMENT_FORMS.sofort, PAYMENT_FORMS.googlePay, PAYMENT_FORMS.applePay, PAYMENT_FORMS.bankWire].includes(method)

    const newPaymentMethods = (method) =>
      isMethodWithoutInfo(method) ? null : (
        <div className='new-paymethod-content new-paymethod-content--with-bottom-margin'>{activeMethodBody()}</div>
      )

    const getPaymentMethod = (method: string, firstMethod = false) => {
      const isCard = method === PAYMENT_FORMS.card

      const isKlarnaDisabled =
        method === PAYMENT_FORMS.klarna &&
        (paymentStore.payerForms?.formType === PAYER_FORM_TYPE.business ||
          ordersStore.data?.payerData?.formType === PAYER_FORM_TYPE.business)

      const isPaymethodDisabled =
        (activeMethod === PAYMENT_FORMS.applePay && !appleAvailability?.applePay) ||
        (activeMethod === PAYMENT_FORMS.googlePay && !googleAvailability?.googlePay) ||
        isKlarnaDisabled

      if (isKlarnaDisabled && activeMethod === PAYMENT_FORMS.klarna) {
        if (allowedMethods.length > 1) {
          setActiveMethod(allowedMethods.find((method) => method !== PAYMENT_FORMS.klarna))
        } else {
          setActiveMethod('')
        }
      }

      const iconSrc = getPaymentMethodIcon(method, cardProvider, isCard && !firstMethod)
      const getImg = (icon) =>
        createImg({
          paymentForm: method,
          className: `${method}-img ${I18n.locale} ${cardProvider}`,
          src: icon,
          style: firstMethod ? { height: PAYMENT_LOGOS_LIST_HEIGHT[method] } : {},
        })

      const showOrSeparator = firstMethod && paymentMethodsToShow.length > 1

      return (
        <label key={method} htmlFor={`method_${method}`} className='payment-method-label'>
          <EloCard
            type='selectable'
            className={classNames({ 'payment-method-card': firstMethod })}
            contentClassName='elo-card__content--without-margin'
            selected={`method_${method}` === `method_${activeMethod}`}
            handleSelect={() => selectPaymentMethod(method)}
          >
            <div className='payment-method-card__content'>
              {firstMethod ? (
                <>
                  <div>
                    <EloRadioButton
                      checked={`method_${method}` === `method_${activeMethod}`}
                      onChange={() => {}}
                      disabled={isPaymethodDisabled}
                      className={I18n.locale}
                      borderColor={color}
                      id={`method_${method}`}
                    >
                      {getPaymentFormsLabels()[method]}
                    </EloRadioButton>
                  </div>
                  <div className='payment-method-card__logos'>
                    {Array.isArray(iconSrc) ? iconSrc.map((icon) => getImg(icon)) : getImg(iconSrc)}
                  </div>
                </>
              ) : (
                <div>
                  <div className='payment-main-methods__logos'>
                    {Array.isArray(iconSrc) ? iconSrc.map((icon) => getImg(icon)) : getImg(iconSrc)}
                    {isCard && <span>+2</span>}
                  </div>
                  <div>
                    <EloRadioButton
                      checked={`method_${method}` === `method_${activeMethod}`}
                      onChange={() => {}}
                      disabled={isPaymethodDisabled}
                      className={I18n.locale}
                      borderColor={color}
                      id={`method_${method}`}
                    >
                      {getPaymentFormsLabels()[method]}
                    </EloRadioButton>
                  </div>
                </div>
              )}
            </div>
          </EloCard>

          {firstMethod && isActive(method) && newPaymentMethods(method)}

          {showOrSeparator && (
            <div className='payment-method-visual-separation'>
              <div />
              {I18n.t('react.shared.or')}
              <div />
            </div>
          )}
        </label>
      )
    }

    return (
      <div className='paymethods new-paymethods'>
        {!paymentMethodsToShow.length && (
          <NoData message={I18n.t('react.shared.payment_methods.no_available_method')} />
        )}

        {getPaymentMethod(preferredOrFirstPaymentMethod, true)}

        <div className='payment-mobile-methods'>
          <div className='scroll-wrapper' ref={scrollWrapperRef}>
            {[...paymentMethodsWithoutFirst, ...restPaymentMethods].map((method) => getPaymentMethod(method))}
          </div>
          <PaymentMethodSlider
            itemsLength={paymentMethodsToShow.length}
            scrollWrapperRef={scrollWrapperRef}
            itemWidth={144}
            itemGap={16}
          />
        </div>

        <div className='payment-methods-desktop-tablet'>
          <div className='payment-main-methods'>
            {newMethodWithAccordion
              ? paymentMethodsWithoutFirst.map((method) => getPaymentMethod(method))
              : [...paymentMethodsWithoutFirst, ...restPaymentMethods].map((method) => getPaymentMethod(method))}
          </div>
        </div>

        {paymentMethodsWithoutFirst.includes(activeMethod) && newPaymentMethods(activeMethod)}

        {newMethodWithAccordion && (
          <div className='payment-methods-desktop-tablet'>
            {!!restPaymentMethods.length && (
              <div className='payment-method-accordion'>
                <EloAccordion
                  size='medium'
                  items={[
                    {
                      headline: I18n.t('react.shop.payment.show_more_options'),
                      content: (
                        <div className='payment-rest-methods'>
                          {restPaymentMethods.map((method) => getPaymentMethod(method))}
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        )}

        {restPaymentMethods.includes(activeMethod) && newPaymentMethods(activeMethod)}
      </div>
    )
  }

  return (
    <div className='paymethods'>
      {!paymentMethodsToShow.length && <NoData message={I18n.t('react.shared.payment_methods.no_available_method')} />}
      {paymentMethodsToShow.map((method, index) => {
        if (
          method === PAYMENT_FORMS.card &&
          (cardProvider === PAYMENT_PROVIDERS.stripe || cardProvider === PAYMENT_PROVIDERS.elopageConnect) &&
          !allowedToUseStripe()
        )
          return <Fragment key={method} />

        const color = themeStore && themeStore?.ppTemplate?.theme?.paymentPageColor
        const enabledStyle = color
          ? {
              backgroundColor: color,
              borderColor: color,
            }
          : {}

        const isKlarnaDisabled =
          method === PAYMENT_FORMS.klarna &&
          (paymentStore.payerForms?.formType === PAYER_FORM_TYPE.business ||
            ordersStore.data?.payerData?.formType === PAYER_FORM_TYPE.business)

        const isPaymethodDisabled =
          (activeMethod === PAYMENT_FORMS.applePay && !appleAvailability?.applePay) ||
          (activeMethod === PAYMENT_FORMS.googlePay && !googleAvailability?.googlePay) ||
          isKlarnaDisabled

        if (isKlarnaDisabled && activeMethod === PAYMENT_FORMS.klarna) {
          if (allowedMethods.length > 1) {
            setActiveMethod(allowedMethods.find((method) => method !== PAYMENT_FORMS.klarna))
          } else {
            setActiveMethod('')
          }
        }

        const iconSrc = getPaymentMethodIcon(method, cardProvider)
        const getImg = (icon) =>
          createImg({
            paymentForm: method,
            className: `${method}-img ${I18n.locale} ${cardProvider}`,
            src: icon,
            style: { height: PAYMENT_LOGOS_LIST_HEIGHT[method] },
          })

        const showVisualSeparation =
          paymentMethodsToShow.length > 1 && !index && !!preferredPaymentMethods?.length && visualSeparationEnabled

        const isMethodWithoutInfo = [
          PAYMENT_FORMS.paypal,
          PAYMENT_FORMS.sofort,
          PAYMENT_FORMS.googlePay,
          PAYMENT_FORMS.applePay,
          PAYMENT_FORMS.bankWire,
        ].includes(method)

        const newPaymentMethods = isMethodWithoutInfo ? null : (
          <div className='new-paymethod-content'>{activeMethodBody()}</div>
        )

        return (
          <label key={method} htmlFor={`method_${method}`} className='payment-method-label'>
            <div className={`payment-method ${isActive(method) ? 'selected' : ''}`}>
              <div className='select-paymethod' data-value={method} onClick={() => selectPaymentMethod(method)}>
                <div className='row method-container align-items-center'>
                  <div className='col-auto label-container'>
                    {isNewPaymentMethodsUI ? (
                      <EloRadioButton
                        checked={`method_${method}` === `method_${activeMethod}`}
                        onChange={() => {}}
                        disabled={isPaymethodDisabled}
                        className={I18n.locale}
                        borderColor={color}
                        id={`method_${method}`}
                      >
                        {getPaymentFormsLabels()[method]}
                      </EloRadioButton>
                    ) : (
                      <RadioField
                        id={`method_${method}`}
                        label={getPaymentFormsLabels()[method]}
                        value={`method_${activeMethod}`}
                        style={enabledStyle}
                        disabled={isPaymethodDisabled}
                        className={I18n.locale}
                        onChange={() => {}}
                      />
                    )}
                  </div>
                  <div className='select-paymethod__img-wrapper col-auto ml-auto'>
                    {Array.isArray(iconSrc) ? iconSrc.map((icon) => getImg(icon)) : getImg(iconSrc)}
                  </div>
                </div>
              </div>
              {isActive(method) &&
                (isNewPaymentMethodsUI ? (
                  newPaymentMethods
                ) : (
                  <div className='paymethod-content'>{activeMethodBody()}</div>
                ))}
            </div>
            {showVisualSeparation && (
              <div className='paymethods--visual-separation'>
                <hr />
                {capitalize(I18n.t('react.shared.or'))}
                <hr />
              </div>
            )}
          </label>
        )
      })}
    </div>
  )
}
