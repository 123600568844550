import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import withStyles from 'react-jss'

import { FormattedTicketDates } from '@elo-kit/components/formatters/formatted-ticket-dates/FormattedTicketDates'

import ProductCover from 'ui/ProductCover'

import { createId } from '@elo-kit/utils/general.utils'
import { ascendingSort } from 'utils/helpers.utils'
import bundleStyles from './bundleStyles'

@observer
class ProductAddons extends Component {
  render() {
    const { productAddons, classes } = this.props

    const sortedList = ascendingSort(productAddons)

    return sortedList.map((productAddon, index) => {
      const { addon } = productAddon
      const { ticket, ticketDate = {} } = productAddon
      const coverInfo = {
        cover: addon.cover,
        form: addon.form,
        active: addon.active,
      }
      const isEvent = productAddon.addonType === 'event'
      const ticketTypeIconClasses = classNames('fas', classes.ticketDetailsIcon, {
        'fa-map-marker-alt': ticket && !ticket.online,
        'fa-globe': ticket && ticket.online,
      })
      const ticketDateIconClasses = classNames(classes.ticketDetailsIcon, 'fas fa-clock')
      return (
        <div className={classes.productItem} key={createId(productAddon.id, index)}>
          <ProductCover product={coverInfo} size='big' />
          <div className={classes.productNameContainer}>
            {isEvent && (
              <div className={classes.ticketsCountBadge}>
                {I18n.t('react.shared.tickets_count', { count: productAddon.ticketsCount })}
              </div>
            )}
            <div className={classes.productName}>{addon.name}</div>
            {isEvent && (
              <Fragment>
                <div className={classes.ticketDetailsText}>
                  <i className={ticketTypeIconClasses} />
                  <div>{ticket.locationName}</div>
                </div>
                <div className={classes.ticketDetailsText}>
                  <i className={ticketDateIconClasses} />
                  <FormattedTicketDates ticketDate={ticketDate} />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )
    })
  }
}

export default withStyles(bundleStyles)(ProductAddons)
