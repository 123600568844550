import React, { useEffect, Fragment, useState } from 'react'
import dynamic from 'next/dynamic'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import Input from 'ui-old/InputNext'
import { CountrySelector } from '@elo-kit/components/elo-ui/country-selector/CountrySelector'
import { SelectField } from '@elo-kit/components/form/select-field/SelectField'
import { EloPhoneInput } from '@elo-ui/components/elo-inputs'

import { validateVATId, shouldVATIdDisplay } from 'utils/validators-vat.utils'
import { removeSpaces } from 'utils/helpersShared.utils'

import {
  ID_SELLER_WITH_RESTRICTED_COUNTRIES,
  RESTRICT_COUNTRIES,
  getSalutationOptions,
} from '@elo-kit/constants/profile.constants'
import { EU_VAT_REGEX } from 'constants/regex.constants'
import { COUNTRIES_WITH_STATES, SWITZERLAND_COUNTRY_CODE } from 'constants/countries.constants'

import { useShopStores } from 'shop/hooks/use-store'

const DynamicGeocoderAutocomplete = dynamic(() => import('./GeocoderAutocomplete'), {
  ssr: false,
})

type ConfigValues = {
  visible: string
  required?: string
}

type Config = Partial<
  Record<
    | 'company'
    | 'salutation'
    | 'fullName'
    | 'email'
    | 'streetNumber'
    | 'street'
    | 'city'
    | 'zip'
    | 'country'
    | 'vatNo'
    | 'phone'
    | 'gift'
    | 'additionalAddress',
    ConfigValues
  >
>

interface AttendeeDetails {
  company?: string
  country: {
    code: string
    eu?: boolean
  }
  salutation?: string
  firstName?: string
  lastName?: string
  email?: string
  emailConfirmation?: string
  street?: string
  streetNumber?: string
  city?: string
  zip?: string
  vatNo?: string
  phone?: string
  additionalAddress?: string
  state?: string
}

interface Props {
  config: Config
  data: AttendeeDetails
  emailConfirmation?: string
  forceDirty: boolean
  handleInput: (name: string, value: { eu?: boolean } | string) => void
  handleValid: (name: string, value: boolean) => void
  sellerCountryCode: string
  withDates?: boolean
  fullCountriesList?: boolean
}

export const PayerFormsFields: React.FC<Props> = observer(function PayerFormsFields(props) {
  const [autofilledCountryCode, setAutofilledCountryCode] = useState()
  const [invalidSalutation, setInvalidSalutation] = useState(false)
  const [invalidState, setInvalidState] = useState(false)
  const [invalidStreet, setInvalidStreet] = useState(false)
  const [showZipError, setShowZipError] = useState(false)
  const [streetTouched, setStreetTouched] = useState(false)
  const [phoneTouched, setPhoneTouched] = useState(false)

  const {
    config,
    data,
    emailConfirmation,
    forceDirty,
    handleInput,
    handleValid,
    sellerCountryCode,
    withDates,
    fullCountriesList,
  } = props
  const { paymentStore, countriesStore, sellerStore, paymentCountriesStore, trackingUserEventsStore } = useShopStores()

  const isSpecialSellerId = sellerStore.item?.id === ID_SELLER_WITH_RESTRICTED_COUNTRIES

  const paymentCountries =
    !fullCountriesList && paymentCountriesStore.paymentCountries.length
      ? paymentCountriesStore.paymentCountries
      : countriesStore.list

  const filteredCountries = isSpecialSellerId
    ? countriesStore.list.filter(({ id }) => RESTRICT_COUNTRIES.includes(id))
    : paymentCountries

  const countryCodes = filteredCountries.map((item) => item.id)

  const isStateFieldVisible = (countryCode) => COUNTRIES_WITH_STATES.includes(countryCode)

  useEffect(() => {
    paymentStore.checkVatId()

    if (isStateFieldVisible(data?.country?.code)) {
      countriesStore.fetchStates(data.country.code)
    }

    if (!fullCountriesList && !countryCodes.includes(data?.country?.code)) {
      const firstCountry = filteredCountries[0]
      const countryValue = {
        code: firstCountry.id,
        label: firstCountry.name,
        eu: firstCountry.euMember,
        value: firstCountry.id,
      }
      handleInput('country', countryValue)
    }
  }, [data?.country?.code])

  useEffect(() => {
    if (config?.salutation?.required === 'on' && !data?.salutation?.length && forceDirty) {
      setInvalidSalutation(true)
      handleValid('salutation', false)
    } else if (
      config?.salutation?.required === 'off' ||
      (paymentStore.store.attendees.active && data?.salutation?.length)
    ) {
      setInvalidSalutation(false)
      handleValid('salutation', true)
    }
  }, [data?.salutation, config?.salutation?.required, forceDirty, paymentStore.store.attendees.active])

  useEffect(() => {
    if (isStateFieldVisible(data?.country?.code) && !data?.state?.length && forceDirty) {
      setInvalidState(true)
      handleValid('state', false)
    } else {
      handleValid('state', true)
    }
  }, [data?.state, data?.country?.code, forceDirty])

  useEffect(() => {
    if (config?.street?.required === 'on') {
      handleValid('street', !!data?.street)
      streetTouched && setInvalidStreet(!data?.street)
    } else {
      handleValid('street', true)
      setInvalidStreet(false)
    }
  }, [data?.street, config?.street?.required])

  useEffect(() => {
    if (config?.phone?.required === 'on') {
      handleValid('phone', !!data?.phone)
    } else {
      handleValid('phone', true)
    }
  }, [data?.phone, config?.phone?.required])

  useEffect(() => {
    if (config?.zip?.required === 'on' && config?.country?.required === 'on') {
      if (!paymentStore.zipMatchCountry) {
        setShowZipError(!paymentStore.validateZip())
      } else {
        setShowZipError(false)
      }
    }
  }, [data?.zip, data?.country.code, paymentStore.zipMatchCountry])

  useEffect(() => {
    if (
      forceDirty &&
      !data?.street?.length &&
      config?.street?.required === 'on' &&
      !streetTouched &&
      withDates === undefined
    ) {
      setInvalidStreet(true)
    }
  }, [forceDirty])

  useEffect(() => {
    if (paymentStore.store.validateOnSubmit) {
      setPhoneTouched(true)
    }
  }, [paymentStore.store.validateOnSubmit])

  const showVatIdField = shouldVATIdDisplay(config)
  const vatValidation = validateVATId(paymentStore.vatData, config, data)

  const emailConfirmationOn = emailConfirmation === 'on'

  const {
    validateCompanyFields = false,
    vatServiceUnavailable = false,
    validateCountryCompliance = false,
    notGermany = false,
  } = vatValidation

  const handleVatNoInput = (value: string, countryCode: string, showVatIdField?: boolean) => {
    const vat = removeSpaces(value)
    const isVatRequired = config?.vatNo?.required === 'on'
    const isVatValid = !isVatRequired && !vat

    handleInput('vatNo', vat)

    if (showVatIdField && EU_VAT_REGEX[countryCode]?.test(vat)) {
      paymentStore.checkVatId()
      handleValid('vatNo', paymentStore.vatData.valid)
    } else {
      paymentStore.updateVatData({ ...paymentStore.vatData, valid: isVatValid })
      handleValid('vatNo', isVatValid)
    }
    handleInput('company', data?.company)
  }

  const handleVatNoBlur = (countryCode: string, showVatIdField?: boolean) => {
    const isVatRequired = config?.vatNo?.required === 'on'
    const isVatValid = !isVatRequired && !data?.vatNo

    if (showVatIdField && EU_VAT_REGEX[countryCode]?.test(data?.vatNo)) {
      paymentStore.checkVatId()
      handleValid('vatNo', paymentStore.vatData.valid)
      trackVatValidation(data.vatNo, paymentStore.vatData.valid)
    } else {
      paymentStore.updateVatData({ ...paymentStore.vatData, valid: isVatValid })
      trackVatValidation(data.vatNo, isVatValid)
      handleValid('vatNo', isVatValid)
    }
  }

  const onPlaceSelect = (value) => {
    const { street, address_line1, housenumber, postcode, city, country_code } = value.properties

    if (config?.street?.visible === 'on') {
      street ? handleInput('street', street) : address_line1 && handleInput('street', address_line1)
    }

    const splitZipCodes = postcode?.split(',')
    const zipValue = splitZipCodes?.length > 1 ? '' : postcode

    config?.streetNumber?.visible === 'on' && housenumber && handleInput('streetNumber', housenumber)
    config?.zip?.visible === 'on' && postcode && handleInput('zip', zipValue)
    config?.city?.visible === 'on' && city && handleInput('city', city)
    config?.country?.visible === 'on' && country_code && setAutofilledCountryCode(country_code.toUpperCase())
  }

  const streetContainerClassNames = classNames({
    'col-12': config?.streetNumber?.visible === 'off',
    'col-9 pr-1': config?.streetNumber?.visible === 'on',
  })

  const streetClassNames = classNames('form-group', {
    'invalid-street': invalidStreet,
  })

  const isVATNotRequired = () => {
    const isAllOfflineTickets = paymentStore.buildedOrder.sellables?.every(
      ({ categoryKey }) => categoryKey === 'offline_event'
    )

    return sellerCountryCode === data.country.code || isAllOfflineTickets
  }

  const sortCountries = (country) =>
    country?.properties?.country_code === (data?.country?.code.toLowerCase() || 'de') ? -1 : 1

  const trackVatValidation = (value: string, isValid: boolean) => {
    trackingUserEventsStore.track({
      pageType: 'shop_checkout',
      eventType: 'vat_id_check',
      withExperiment: true,
      payload: {
        value,
        isValid,
      },
    })
  }

  const isEu = data?.country?.eu
  const isSwiss = data?.country?.code === SWITZERLAND_COUNTRY_CODE
  const isEuOrSwiss = isEu || isSwiss

  return (
    <Fragment>
      {config?.company?.visible === 'on' && (
        <Input
          wrapClass='form-group'
          class='form-control'
          name='company'
          placeholder={I18n.t('react.shop.payment.payer_form.company')}
          value={data?.company}
          vatComplianceField={paymentStore.vatData?.name}
          forceDirty={forceDirty}
          validations={[
            (config?.company?.required === 'on' || validateCompanyFields) && 'required',
            validateCompanyFields && notGermany && 'vatCompliance',
          ]}
          handleInput={(value) => handleInput('company', value)}
          handleValid={(isValid) => handleValid('company', isValid)}
          testId='payer-form-field-company'
          autoComplete='organization'
        />
      )}
      {config?.salutation?.visible === 'on' && (
        <div className='mb-3' style={{ maxWidth: '200px' }}>
          <SelectField
            containerClassName='field--no-margin is-invalid zip-container'
            placeholder={I18n.t('react.shared.checkout.salutation')}
            value={data?.salutation}
            options={getSalutationOptions()}
            onChange={(value) => {
              handleInput('salutation', value)
              setInvalidSalutation(false)
              handleValid('salutation', true)
            }}
            required={config?.salutation?.required === 'on'}
            valid={!invalidSalutation}
            dataTestId='payer-form-field-salutation'
          />
          {invalidSalutation && <div className='invalid-feedback'>{I18n.t('react.shared.validations.required')}</div>}
        </div>
      )}
      {config?.fullName?.visible === 'on' && (
        <div className='row'>
          <div className='col pr-1'>
            <Input
              wrapClass='form-group'
              class='form-control'
              name='firstName'
              placeholder={I18n.t('react.shop.payment.payer_form.first_name')}
              value={data?.firstName}
              forceDirty={forceDirty}
              minlength={2}
              maxlength={40}
              validations={[config?.fullName?.required === 'on' && 'required', 'minlength', 'maxlength']}
              handleInput={(value) => handleInput('firstName', value)}
              handleValid={(isValid) => handleValid('firstName', isValid)}
              testId='payer-form-field-full_name'
              autoComplete='given-name'
            />
          </div>
          <div className='col pl-1'>
            <Input
              wrapClass='form-group'
              class='form-control'
              placeholder={I18n.t('react.shop.payment.payer_form.last_name')}
              value={data?.lastName}
              forceDirty={forceDirty}
              minlength={2}
              maxlength={40}
              validations={[config?.fullName?.required === 'on' && 'required', 'minlength', 'maxlength']}
              handleInput={(value) => handleInput('lastName', value)}
              handleValid={(isValid) => handleValid('lastName', isValid)}
              testId='payer-form-field-last_name'
              autoComplete='family-name'
            />
          </div>
        </div>
      )}
      {config?.email?.visible === 'on' && (
        <Input
          wrapClass='form-group'
          class='form-control'
          name='email'
          placeholder={I18n.t('react.shop.payment.payer_form.email')}
          value={data?.email}
          forceDirty={forceDirty}
          validations={[config?.email?.required === 'on' && 'required', 'email']}
          handleInput={(value) => handleInput('email', (value || '').trim())}
          handleValid={(isValid) => handleValid('email', isValid)}
          testId='payer-form-field-email'
          autoComplete='email'
        />
      )}
      {emailConfirmationOn && (
        <Input
          showCustomError
          disableCopyPaste
          wrapClass='form-group'
          className='xtra'
          class='form-control'
          name='emailConfirmation'
          placeholder={I18n.t('react.shop.payment.payer_form.confirm_email')}
          emailData={data?.email}
          value={data?.emailConfirmation}
          forceDirty={forceDirty}
          validations={[emailConfirmationOn && 'required', 'emailConfirmation']}
          handleInput={(value) => handleInput('emailConfirmation', (value || '').trim())}
          handleValid={(isValid) => handleValid('emailConfirmation', isValid)}
          testId='payer-form-field-confirm_email'
          autoComplete='email'
        />
      )}
      <Fragment>
        <div className='row'>
          <div className={streetContainerClassNames}>
            {config?.street?.visible === 'on' && (
              <div
                className={streetClassNames}
                onBlur={() => setInvalidStreet(config.street.required === 'on' ? !data?.street : false)}
                data-testid='geocoder-autocomplete'
              >
                <DynamicGeocoderAutocomplete
                  placeholder={I18n.t('react.shop.payment.payer_form.street')}
                  onUserInput={(value) => {
                    handleInput('street', value)

                    config?.street?.required === 'on' && setStreetTouched(true)
                  }}
                  value={data?.street}
                  suggestionsFilter={(val) => !!val.length && val.sort(sortCountries)}
                  debounceDelay={200}
                  skipDetails
                  skipIcons
                  lang={I18n.locale}
                  allowNonVerifiedStreet={false}
                  placeSelect={onPlaceSelect}
                  countryCodes={countryCodes.filter((val) => typeof val === 'string' && !val.includes('-'))}
                />
                {invalidStreet && <div className='invalid-feedback'>{I18n.t('react.shared.validations.required')}</div>}
              </div>
            )}
          </div>
          <div
            className={classNames({
              'col-12': config?.street?.visible === 'off',
              'col-3 pl-1': config?.street?.visible === 'on',
            })}
          >
            {config?.streetNumber?.visible === 'on' && (
              <Input
                wrapClass='form-group'
                class='form-control'
                name='streetNumber'
                placeholder={I18n.t('react.shop.payment.payer_form.street_number')}
                value={data?.streetNumber}
                forceDirty={forceDirty}
                validations={[config?.streetNumber?.required === 'on' && 'required']}
                handleInput={(value) => handleInput('streetNumber', value)}
                handleValid={(isValid) => handleValid('streetNumber', isValid)}
                testId='payer-form-field-street_number'
                autoComplete='street-line2'
              />
            )}
          </div>
        </div>
        {config?.additionalAddress?.visible === 'on' && (
          <Input
            wrapClass='form-group'
            class='form-control'
            name='additionalAddress'
            placeholder={I18n.t('react.shop.payment.payer_form.additional_address')}
            value={data?.additionalAddress}
            forceDirty={forceDirty}
            validations={[config?.additionalAddress?.required === 'on' && 'required']}
            handleInput={(value) => handleInput('additionalAddress', value)}
            handleValid={(isValid) => handleValid('additionalAddress', isValid)}
            testId='payer-form-field-additional_address'
            autoComplete='street-line3'
          />
        )}
        <div className='row'>
          <div
            className={classNames({
              'col-12': config?.city?.visible === 'off',
              'col-3 pr-1': config?.city?.visible === 'on',
            })}
          >
            {config?.zip?.visible === 'on' && (
              <Input
                wrapClass='form-group'
                class='form-control'
                placeholder={I18n.t('react.shop.payment.payer_form.zip')}
                value={data?.zip}
                forceDirty={forceDirty}
                validations={[config?.zip?.required === 'on' && 'required', 'matchWithCountry']}
                handleInput={(value) => handleInput('zip', value)}
                handleValid={(isValid) => handleValid('zip', isValid)}
                isInvalidZip={showZipError}
                testId='payer-form-field-zip'
                autoComplete='postal-code'
              />
            )}
          </div>
          <div
            className={classNames({
              'col-12': config?.zip?.visible === 'off',
              'col-9 pl-1': config?.zip?.visible === 'on',
            })}
          >
            {config?.city?.visible === 'on' && (
              <Input
                wrapClass='form-group'
                class='form-control'
                placeholder={I18n.t('react.shop.payment.payer_form.city')}
                value={data?.city}
                forceDirty={forceDirty}
                validations={[config?.city?.required === 'on' && 'required']}
                handleInput={(value) => handleInput('city', value)}
                handleValid={(isValid) => handleValid('city', isValid)}
                testId='payer-form-field-city'
                autoComplete='address-level2'
              />
            )}
          </div>
        </div>
      </Fragment>
      {config?.country?.visible === 'on' && (
        <div className='mb-3'>
          <CountrySelector
            forceDirty={forceDirty}
            validations={[
              config?.country?.required === 'on' && 'required',
              validateCountryCompliance && !!data?.vatNo && 'countryCompliance',
            ]}
            autofilledCountryCode={autofilledCountryCode}
            handleAutofillInput={handleInput}
            value={data?.country?.code}
            handleInput={(value) => {
              handleInput('country', value)
            }}
            handleValid={(isValid) => {
              handleValid('country', isValid)
              handleInput('company', data?.company)
              handleInput('state', null)
            }}
            vatId={data?.vatNo}
            countriesList={filteredCountries}
            fetchList={countriesStore.fetchList}
            dataTestId='country-selector'
          />
        </div>
      )}
      {isStateFieldVisible(data?.country?.code) && (
        <div className='mb-3'>
          <SelectField
            required
            onChange={(value) => {
              handleInput('state', value)
              setInvalidState(false)
              handleValid('state', true)
            }}
            value={data?.state}
            options={countriesStore.getStatesSelectOptions(data.country.code)}
            components={{ IndicatorSeparator: () => null }}
            containerClassName='field--no-margin is-invalid'
            valid={!invalidState}
            key={data.country.code}
          />
          {invalidState && <div className='invalid-feedback'>{I18n.t('react.shared.validations.required')}</div>}
        </div>
      )}
      {!!showVatIdField && (
        <Input
          wrapClass='form-group'
          class='payer-phone form-control'
          name='vatNo'
          locale={data?.country?.code}
          placeholder={I18n.t('react.shop.payment.payer_form.vat')}
          value={data?.vatNo}
          forceDirty={forceDirty}
          successMsg={isEuOrSwiss ? I18n.t('react.shop.payment.payer_form.vat_valid') : ''}
          validations={
            isEuOrSwiss
              ? [
                  config?.vatNo?.required === 'on' &&
                    !isVATNotRequired() &&
                    (sellerCountryCode !== data?.country?.code || validateCountryCompliance) &&
                    'required',
                  'vat',
                  'viesVatValidation',
                ]
              : []
          }
          handleBlur={() => (isEuOrSwiss ? handleVatNoBlur(data?.country?.code, isEuOrSwiss) : {})}
          handleInput={(value) => handleVatNoInput(value, data?.country?.code, isEuOrSwiss)}
          handleValid={(isValid) => handleValid('vatNo', isValid)}
          vatValidByVies={isEuOrSwiss ? paymentStore.vatData?.valid || vatServiceUnavailable : true}
          isValidVat={isEuOrSwiss ? paymentStore.vatData.valid : true}
          isValidCountry={isEuOrSwiss ? paymentStore.vatData.validCountry : true}
          isVat
          testId='payer-form-field-vat'
        />
      )}
      {config?.phone?.visible === 'on' && (
        <EloPhoneInput
          className='payment-phone'
          placeholder={I18n.t('react.shop.payment.payer_form.phone')}
          value={data?.phone}
          onChange={(value) => handleInput('phone', value)}
          onBlur={() => {
            config?.phone?.required === 'on' && !phoneTouched && setPhoneTouched(true)
          }}
          error={config?.phone?.required === 'on' && phoneTouched && !data?.phone}
          errorText={I18n.t('react.shared.validations.required')}
          country={data?.country?.code?.toLowerCase()}
        />
      )}
    </Fragment>
  )
})
