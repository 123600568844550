import React from 'react'

import { GooglePayOneClick } from 'shared/components/purchase-methods/GooglePayOneClick'
import { ApplePayOneClick } from 'shared/components/purchase-methods/ApplePayOneClick'
import { PurchaseTerms } from 'shop/components/payment/new/purchaseTerms'

import { convertPriceForStripe } from '@elo-kit/utils/stripe.utils'

import { useShopStores } from 'shop/hooks/use-store'

export const OneClickPayment = () => {
  const { sellerStore, paymentStore, cancellationTermsStore, themeStore } = useShopStores()

  return (
    <div className='express-checkout'>
      <div className='express-checkout__title'>{I18n.t('react.shared.express_checkout')}</div>
      <GooglePayOneClick
        googleProvider={sellerStore.item.googlePayProvider}
        googlePubKey={sellerStore.googlePublicKey}
        payOptions={{
          amount: convertPriceForStripe(paymentStore.totalPrice()),
          currency: paymentStore.currency,
        }}
      />
      <ApplePayOneClick
        appleProvider={sellerStore.item.applePayProvider}
        applePubKey={sellerStore.applePublicKey}
        payOptions={{
          amount: convertPriceForStripe(paymentStore.totalPrice()),
          currency: paymentStore.currency,
        }}
      />

      <div className='purchase express-checkout__purchase'>
        <PurchaseTerms
          isFree={false}
          link={paymentStore.product?.cancelLink}
          htmlTerms={cancellationTermsStore.item}
          checked={paymentStore.store?.terms}
          onCheckboxChange={(terms) => paymentStore.submitTerms(terms)}
          productType={paymentStore.product?.form}
          invoiceToken={paymentStore.invoice?.token}
          areHiddenCancellationTerms={
            paymentStore.payerFromType === 'business' && paymentStore.product?.hideB2bCancellationTerm
          }
          sellerUsername={sellerStore.item.username}
          isPowerSeller={sellerStore.item.powerSeller}
          usePowerSellerDocument={sellerStore.item.documentConfiguration?.termsOfBusiness}
          paymentPageColor={themeStore.ppTemplate?.theme?.paymentPageColor}
        />
      </div>
    </div>
  )
}
