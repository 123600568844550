import React, { Component } from 'react'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { EloTooltipMore } from '@elo-kit/components/elo-ui/elo-tooltip-more/EloTooltipMore'
import { RadioField } from '@elo-kit/components/form/radio-field/RadioField'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { I18nContext } from '@elo-kit/components/i18n/i18n'
import { getFormattedTicketDates } from 'utils/tickets.utils'
import PricingPlansList from 'shared/components/pricing-plans/PricingPlansList'
import { getPaymentPeriodLabels } from '@elo-kit/constants/paymentSetting.constants'
import { UPSELLS_TYPES } from 'constants/productsShared.constants'
import {
  ORDER_BUMPS_TABS_KEYS,
  ORDER_BUMPS_FIELDS_KEYS,
  ORDER_BUMPS_ARROW_KEYS,
  ORDER_BUMPS_ARROW_TYPES,
  ORDER_BUMPS_PREVIEW_TYPES,
  ORDER_BUMPS_VIEW_TYPES_KEYS,
  ORDER_BUMPS_VIEW_FIELD_KEY,
  ORDER_BUMPS_ALIGNMENT_TYPES,
  ORDER_BUMPS_ALIGNMENT_FIELD_KEY,
} from 'constants/orderBumps.constants'
import { FREE_PRICE } from 'constants/general.constants'
import { linkToImage } from 'utils/ssr.utils'
import { ClientOnly } from 'shared/components/ClientOnly'

import NoImage from 'ui/order-bump-item/icons/NoImage.svg'
import NoImageLarge from 'ui/order-bump-item/icons/NoImageLarge.png'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

import ClassicAltArrows from './arrows/ClassicAltArrows'
import ClassicArrows from './arrows/ClassicArrows'
import DottedArrows from './arrows/DottedArrows'
import RepeatingArrows from './arrows/RepeatingArrows'
import DefaultArrows from './arrows/DefaultArrows'
import SpeedUpArrows from './arrows/SpeedUpArrows'
import LongArrows from './arrows/LongArrows'
import WavesArrows from './arrows/WavesArrows'
import orderBumpStyles from './OrderBumpItem.styles'

const { additionalTextActive, additionalTextText } = ORDER_BUMPS_FIELDS_KEYS.additionalText
const { headerText } = ORDER_BUMPS_FIELDS_KEYS.header
const { buttonText } = ORDER_BUMPS_FIELDS_KEYS.button
const { arrowsStyle } = ORDER_BUMPS_FIELDS_KEYS.arrows
const { backgroundImage } = ORDER_BUMPS_FIELDS_KEYS.background
const { priceActive } = ORDER_BUMPS_FIELDS_KEYS.product
const { productDescription, productDescriptionActive } = ORDER_BUMPS_FIELDS_KEYS.description

/** Order Bump Item compoment */
export class OrderBumpItemComponent extends Component {
  static contextType = I18nContext

  constructor(props) {
    super(props)

    this.state = {
      previewSelectionValue: false,
    }
  }

  handlePreviewSelectionChange = () => {
    const { previewSelectionValue } = this.state

    this.setState({
      previewSelectionValue: !previewSelectionValue,
    })
  }

  getPricingPlanName = (form) => getPaymentPeriodLabels()[form]

  renderChangePaymentPlanButton = (key = 'price', index = 0, customClassName) => {
    const {
      classes,
      coupon,
      upsell,
      config,
      preview,
      handlePricingPlanChange,
      showSelectPricingPlanButton,
      productItem,
      pricingPlans,
      selectedPricingPlan,
      ownPlans,
      isMobilePreview,
      upsell: { pricingPlans: ownUpsellPricingPlans, ticketId, ticketDate, ticketDateId, ticketsCount, ticket },
    } = this.props

    const product = ownPlans
      ? {
          ...productItem,
          pricingPlans: ownUpsellPricingPlans.reduce(
            (prevPlan, plan) => [
              ...prevPlan,
              {
                ...plan,
                testPeriodEnabled: parseInt(plan.prefs?.testPeriod, 10) > 0,
              },
            ],
            []
          ),
        }
      : productItem

    const withLeftAlign = ORDER_BUMPS_ALIGNMENT_TYPES.left === config[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]
    const isSecView = config[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.sec

    if (!showSelectPricingPlanButton) {
      return null
    }

    const ticketInfo = ticketId
      ? {
          ticketId,
          ticketDateId,
          ticketsCount,
          ticketDate,
          ticket,
        }
      : {}

    /**
     * ID OF TOOLTIP MUST BE UNIQ
     * There are multiple injection hidden for mobile & layout changes
     * Therefore id is so complex!
     */
    return (
      <EloTooltipMore
        id={`${upsell.id}-${key}-${index}-${customClassName ? 'custom' : 'default'}-${
          isMobilePreview ? 'mobile' : 'desktop' // TODO: SSR - isMobilePreview var depend on screen size and in case when generated 'id' is different on SSR - page is broken because element doesn't exist
        }`}
        customIcon={(props) => (
          <EloButton
            id={props.id}
            outlined
            disabled={false}
            className={classNames(classes.rootChangePaymentPlanButton, {
              [classes.rootChangePaymentPlanButtonRight]: !withLeftAlign,
              [classes.rootChangePaymentPlanButtonRelative]: isSecView,
            })}
            onClick={preview && !ownPlans ? () => {} : props.onToggle}
          >
            {I18n.t('react.shared.change_payment_plan')}
          </EloButton>
        )}
      >
        <div className={classes.pricingPlans}>
          <PricingPlansList
            pricingPlans={pricingPlans.reduce(
              (prevPlan, plan) => [
                ...prevPlan,
                {
                  ...plan,
                  testPeriodEnabled: parseInt(plan.prefs?.testPeriod, 10) > 0,
                },
              ],
              []
            )}
            selectedId={selectedPricingPlan?.id}
            forForm
            coupon={((coupon || {}).data || {}).upsells && coupon}
            onSelectPlan={(id) => handlePricingPlanChange(product, id, upsell.id, upsell.position, ticketInfo)}
            keepEnabled
          />
        </div>
      </EloTooltipMore>
    )
  }

  renderBackgroundComposition = () => {
    const { classes } = this.props

    return (
      <>
        <div className={classes.rootBackground} />
        <div className={classes.rootBackgroundImage} />
        <div className={classes.rootBackgroundBorder} />
      </>
    )
  }

  renderEventDetails = (additionalClassName) => {
    const {
      classes,
      upsell: { ticketId, ticket = {}, ticketDate = {}, ticketsCount },
    } = this.props

    if (!ticketId) {
      return null
    }

    return (
      <div className={classNames(classes.rootEvent, additionalClassName)}>
        <div className={classes.rootEventName}>{ticket?.name}</div>
        <div className={classes.rootEventDetails}>
          <div className={classes.eventLocationDetails}>{ticket.locationName}</div>
          <div className={classes.eventTimeDetails}>{getFormattedTicketDates(ticketDate)}</div>
          <div className={classes.eventTimeDetails}>{I18n.t('react.shared.tickets_count_bump', { ticketsCount })}</div>
        </div>
      </div>
    )
  }

  renderAdditionalText = () => {
    const { config = {}, classes, isMobilePreview } = this.props

    if (
      !config[ORDER_BUMPS_TABS_KEYS.additionalText][additionalTextActive] ||
      !config[ORDER_BUMPS_TABS_KEYS.additionalText][additionalTextText]
    ) {
      return null
    }

    return (
      <div
        className={classNames(classes.rootAdditionalText, {
          [classes.rootAdditionalTextMobile]: isMobilePreview,
          [classes.rootAdditionalThirdView]: config[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.third,
        })}
      >
        <div className={classes.additionalTextBg} />
        <div
          className={classes.additionalText}
          dangerouslySetInnerHTML={{
            __html: cleanupHTMLStyleFonts(config[ORDER_BUMPS_TABS_KEYS.additionalText][additionalTextText]),
          }}
        />
      </div>
    )
  }

  renderHeader = () => {
    const { config = {}, classes, isMobilePreview } = this.props

    if (!config[ORDER_BUMPS_TABS_KEYS.header][headerText]) {
      return null
    }

    return (
      <div
        className={classNames(classes.rootHeader, {
          [classes.rootHeaderMobile]: isMobilePreview,
          [classes.rootHeaderMobileSecView]:
            isMobilePreview && config[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.sec,
        })}
      >
        {config[ORDER_BUMPS_TABS_KEYS.header][headerText]}
      </div>
    )
  }

  renderArrows = (type) => {
    const { config = {}, classes, isMobilePreview } = this.props

    const isLeft = type === ORDER_BUMPS_ARROW_TYPES.left
    const isNone = config[ORDER_BUMPS_TABS_KEYS.arrows][arrowsStyle] === ORDER_BUMPS_ARROW_KEYS.no

    let shouldRender = true
    let arrows = null

    switch (config[ORDER_BUMPS_VIEW_FIELD_KEY]) {
      case ORDER_BUMPS_VIEW_TYPES_KEYS.fifth: {
        shouldRender =
          (isLeft && ORDER_BUMPS_ALIGNMENT_TYPES.left === config[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]) ||
          (!isLeft && ORDER_BUMPS_ALIGNMENT_TYPES.right === config[ORDER_BUMPS_ALIGNMENT_FIELD_KEY])
        break
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.third: {
        shouldRender =
          (!isLeft && ORDER_BUMPS_ALIGNMENT_TYPES.left === config[ORDER_BUMPS_ALIGNMENT_FIELD_KEY]) ||
          (isLeft && ORDER_BUMPS_ALIGNMENT_TYPES.right === config[ORDER_BUMPS_ALIGNMENT_FIELD_KEY])
        break
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.sec: {
        shouldRender = !isLeft
        break
      }
    }

    if (isNone || !shouldRender) {
      return null
    }

    switch (config[ORDER_BUMPS_TABS_KEYS.arrows][arrowsStyle]) {
      case ORDER_BUMPS_ARROW_KEYS.speedUp: {
        arrows = <SpeedUpArrows />
        break
      }
      case ORDER_BUMPS_ARROW_KEYS.dotted: {
        arrows = <DottedArrows />
        break
      }
      case ORDER_BUMPS_ARROW_KEYS.repeating: {
        arrows = <RepeatingArrows />
        break
      }
      case ORDER_BUMPS_ARROW_KEYS.long: {
        arrows = <LongArrows />
        break
      }
      case ORDER_BUMPS_ARROW_KEYS.waves: {
        arrows = <WavesArrows />
        break
      }
      case ORDER_BUMPS_ARROW_KEYS.classic: {
        arrows = <ClassicArrows />
        break
      }
      case ORDER_BUMPS_ARROW_KEYS.classicAlt: {
        arrows = <ClassicAltArrows />
        break
      }
      case ORDER_BUMPS_ARROW_KEYS.default: {
        arrows = <DefaultArrows />
        break
      }
    }

    return isLeft ? (
      <div className={classNames(classes.rootArrowsLeft, { [classes.rootArrowsLeftMobile]: isMobilePreview })}>
        <div className={classes.leftArrows}>{arrows}</div>
      </div>
    ) : (
      <div className={classNames(classes.rootArrowsRight, { [classes.rootArrowsRightMobile]: isMobilePreview })}>
        <div className={classes.rightArrows}>{arrows}</div>
      </div>
    )
  }

  renderButton = () => {
    const {
      config = {},
      selectUpsell,
      deselectUpsell,
      selectionValue,
      classes,
      isMobilePreview,
      upsellType,
      upsell,
      productItem,
      selectedPricingPlan,
      ownPlans,
      preview,
      upsell: { pricingPlans = [], ticket, ticketDate, ticketId, ticketDateId, ticketsCount },
    } = this.props

    const { previewSelectionValue } = this.state
    const isBump = upsellType === UPSELLS_TYPES.bump
    const ticketInfo = ticketId
      ? {
          ticket,
          ticketDate,
          ticketId,
          ticketDateId,
          ticketsCount,
        }
      : {}
    const bumpIsRequired = isBump && upsell.prefs?.required

    const onChangeHandle = (e) => {
      if (e && e.target.tagName !== 'INPUT') {
        e.preventDefault()
        e.stopPropagation()
      }

      if (isBump && selectionValue && !bumpIsRequired) {
        return deselectUpsell(selectionValue)
      }

      if (preview) {
        return this.handlePreviewSelectionChange()
      }

      const product = ownPlans
        ? {
            ...productItem,
            pricingPlans: [...pricingPlans],
          }
        : productItem

      return selectUpsell(product, selectedPricingPlan?.id, upsell?.id, upsell?.position, ticketInfo)
    }
    const checkboxValue = preview ? previewSelectionValue : String(selectionValue) === String(upsell.id)

    const controlComponent = isBump ? (
      <CheckboxField
        id={upsell.id}
        checked={checkboxValue}
        onChange={onChangeHandle}
        className={classNames(classes.rootCheckbox, classes.rootCheckboxImportance)}
        disabled={bumpIsRequired}
      />
    ) : (
      <RadioField id={upsell.id} className={classes.rootRadio} value={selectionValue} onChange={onChangeHandle} />
    )

    if (config[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.seven) {
      return bumpIsRequired ? null : controlComponent
    }

    if (config[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.fifth) {
      return (
        <div className={classNames(classes.fifthButtonContainer)}>
          {this.renderArrows(ORDER_BUMPS_ARROW_TYPES.left)}
          <div
            onClick={onChangeHandle}
            className={classNames(classes.rootButton, {
              [classes.rootButtonMobile]: isMobilePreview,
              [classes.rootButtonFifth]: config[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.fifth,
            })}
          >
            <div className={classes.rootCheckboxWithText}>
              {controlComponent}

              <div className={classes.buttonText}>{config[ORDER_BUMPS_TABS_KEYS.button][buttonText]}</div>
            </div>
          </div>
          {this.renderArrows(ORDER_BUMPS_ARROW_TYPES.right)}
        </div>
      )
    }

    return (
      <div
        onClick={onChangeHandle}
        className={classNames(classes.rootButton, {
          [classes.rootButtonMobile]: isMobilePreview,
          [classes.rootButtonSec]: config[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.sec,
          [classes.rootButtonThird]: config[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.third,
        })}
      >
        {this.renderArrows(ORDER_BUMPS_ARROW_TYPES.left)}
        <div className={classes.rootCheckboxWithText} onClick={onChangeHandle}>
          {controlComponent}

          <div className={classes.buttonText}>{config[ORDER_BUMPS_TABS_KEYS.button][buttonText]}</div>
        </div>
        {this.renderArrows(ORDER_BUMPS_ARROW_TYPES.right)}
      </div>
    )
  }

  renderProductImage = () => {
    const { productItem, classes, config, isMobilePreview } = this.props

    let temporaryImage = NoImage

    switch (config[ORDER_BUMPS_VIEW_FIELD_KEY]) {
      case ORDER_BUMPS_VIEW_TYPES_KEYS.fourth: {
        temporaryImage = isMobilePreview ? NoImage : linkToImage(NoImageLarge)
        break
      }
      case ORDER_BUMPS_VIEW_TYPES_KEYS.sec: {
        temporaryImage = linkToImage(NoImageLarge)
        break
      }
      default: {
        temporaryImage = NoImage
      }
    }

    return (
      <img
        className={classes.rootProductImage}
        alt={productItem?.name}
        src={productItem?.covers?.[0]?.cover || temporaryImage}
      />
    )
  }

  renderProduct = () => {
    const { productItem, classes, isMobilePreview, rootProductDescription } = this.props

    return (
      <>
        <div
          className={classNames(classes.rootProduct, classes.rootProductFirst, {
            [classes.rootProductMobile]: isMobilePreview,
            [classes.rootProductMobileFirst]: isMobilePreview,
          })}
        >
          <div className={classes.rootProductImageContainer}>{this.renderProductImage()}</div>
          <div className={classes.rootProductDetailsContainer}>
            <div
              className={classNames(classes.rootProductName, {
                [classes.rootProductNameMobile]: isMobilePreview,
              })}
            >
              {productItem?.name}
            </div>
            <div
              className={classNames(classes.rootProductDescription, classes.rootProductDescriptionWithName)}
              dangerouslySetInnerHTML={{
                __html: cleanupHTMLStyleFonts(rootProductDescription),
              }}
            />
            {this.renderEventDetails(classes.rootEventWithName)}
          </div>
        </div>
        <div
          className={classNames(classes.rootProductDescription, classes.rootProductDescriptionSeparate)}
          dangerouslySetInnerHTML={{
            __html: cleanupHTMLStyleFonts(rootProductDescription),
          }}
        />
        {this.renderEventDetails(classes.rootEventSeparate)}
      </>
    )
  }

  renderProductSec = () => {
    const { productItem, classes, isMobilePreview, rootProductDescription } = this.props

    return (
      <div className={classes.rootProductSecContainer}>
        <div className={classNames(classes.rootProductSec, { [classes.rootProductMobileSec]: isMobilePreview })}>
          <div className={classes.rootProductImageContainerSec}>{this.renderProductImage()}</div>
          <div className={classes.rootProductDetailsContainerSec}>
            <div
              className={classNames(classes.rootProductName, classes.rootProductNameSec, {
                [classes.rootProductNameMobile]: isMobilePreview,
              })}
            >
              {productItem?.name}
            </div>
            {this.renderProductPrice()}
          </div>
        </div>
        <div
          className={classNames(classes.rootProductDescription, classes.rootProductDescriptionSec)}
          dangerouslySetInnerHTML={{
            __html: cleanupHTMLStyleFonts(rootProductDescription),
          }}
        />
        {this.renderEventDetails()}
        {this.renderProductPrice(classes.rootProductPriceContainerMobileSecBot)}
      </div>
    )
  }

  renderProductThird = () => {
    const { productItem, classes, isMobilePreview, rootProductDescription } = this.props

    return (
      <div className={classes.rootProductThirdContainer}>
        <div className={classNames(classes.rootProductThird, { [classes.rootProductMobileThird]: isMobilePreview })}>
          <div className={classes.rootProductDetailsContainerThird}>
            <div className={classNames(classes.rootProductName, classes.rootProductNameThird)}>{productItem?.name}</div>
            {this.renderProductPrice()}
          </div>
        </div>
        <div
          className={classNames(classes.rootProductDescription, classes.rootProductDescriptionThird)}
          dangerouslySetInnerHTML={{
            __html: cleanupHTMLStyleFonts(rootProductDescription),
          }}
        />
        {this.renderEventDetails()}
      </div>
    )
  }

  renderProductFourth = () => {
    const { productItem, classes, isMobilePreview, rootProductDescription } = this.props

    return (
      <div className={classes.rootProductFourthContainer}>
        <div className={classNames(classes.rootProductFourth, { [classes.rootProductMobileFourth]: isMobilePreview })}>
          <div className={classes.productInnerImageMobileFourth}>{this.renderProductImage()}</div>
          <div className={classes.rootProductDetailsContainerFourth}>
            <div
              className={classNames(classes.rootProductName, classes.rootProductNameFourth, {
                [classes.rootProductNameMobile]: isMobilePreview,
              })}
            >
              {productItem?.name}
            </div>
            {this.renderProductPrice()}
          </div>
        </div>
        <div
          className={classNames(classes.rootProductDescription, classes.rootProductDescriptionFourth)}
          dangerouslySetInnerHTML={{
            __html: cleanupHTMLStyleFonts(rootProductDescription),
          }}
        />
        {this.renderEventDetails()}
        <div className={classes.productOuterPriceMobileFourth}>{this.renderProductPrice()}</div>
      </div>
    )
  }

  renderProductFifth = () => {
    const { productItem, classes, isMobilePreview, rootProductDescription } = this.props

    return (
      <>
        <div
          className={classNames(classes.rootProduct, classes.rootProductFifth, {
            [classes.rootProductMobile]: isMobilePreview,
          })}
        >
          <div className={classes.rootProductImageContainer}>{this.renderProductImage()}</div>
          <div className={classes.rootProductDetailsContainer}>
            <div
              className={classNames(classes.rootProductName, {
                [classes.rootProductNameMobile]: isMobilePreview,
              })}
            >
              {productItem?.name}
            </div>
            <div
              className={classNames(classes.rootProductDescription, classes.rootProductDescriptionWithName)}
              dangerouslySetInnerHTML={{
                __html: cleanupHTMLStyleFonts(rootProductDescription),
              }}
            />
            {this.renderEventDetails(classes.rootEventWithName)}
          </div>
        </div>
        <div
          className={classNames(classes.rootProductDescription, classes.rootProductDescriptionMobile)}
          dangerouslySetInnerHTML={{
            __html: cleanupHTMLStyleFonts(rootProductDescription),
          }}
        />
        {this.renderEventDetails(classes.rootEventSeparate)}
      </>
    )
  }

  renderProductSeven = () => {
    const { productItem, classes, isMobilePreview } = this.props

    return (
      <>
        <div
          className={classNames(classes.rootProduct, {
            [classes.rootProductMobile]: isMobilePreview,
          })}
        >
          <div className={classes.rootProductDetailsContainer}>
            <div className={classes.rootProductImageContainer}>{this.renderProductImage()}</div>
            <div>
              <div
                className={classNames(classes.rootProductDescription, classes.rootProductDescriptionWithName)}
                dangerouslySetInnerHTML={{
                  __html: cleanupHTMLStyleFonts(productItem?.description),
                }}
              />
              <div>{this.renderEventDetails(classes.rootEventWithName)}</div>
            </div>
          </div>
        </div>
        <div
          className={classNames(classes.rootProductDescription, classes.rootProductDescriptionMobile)}
          dangerouslySetInnerHTML={{
            __html: cleanupHTMLStyleFonts(productItem?.description),
          }}
        />
        {this.renderEventDetails(classes.rootEventSeparate)}
      </>
    )
  }

  renderProductPrice = (customClassName) => {
    const {
      convertToPrice,
      selectedPricingPlan = {},
      classes,
      isMobilePreview,
      config,
      isFree,
      showSelectPricingPlanButton,
      upsell: { ticketId, ticketsCount },
    } = this.props

    const { currencyId, currencyKey, form, prefs = {}, useNetPrice } = selectedPricingPlan

    const { price, rate1Amount, firstAmount, oldPrice } = prefs

    const currency = currencyId || currencyKey
    const planPrice = price || rate1Amount || firstAmount || 0
    const priceValue = ticketId
      ? convertToPrice(Number(planPrice) * ticketsCount, currencyId)
      : convertToPrice(planPrice, currencyId)
    const oldPriceValue = convertToPrice(oldPrice, currency)
    const indexKey = 'price'
    const freeContainer = (
      <div
        className={classNames(classes.rootProductPriceNew, {
          [classes.rootProductPriceNewFirst]: config[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.first,
        })}
      >
        {FREE_PRICE}
      </div>
    )

    if (!config[ORDER_BUMPS_TABS_KEYS.product][priceActive]) {
      return null
    }

    switch (config[ORDER_BUMPS_VIEW_FIELD_KEY]) {
      case ORDER_BUMPS_VIEW_TYPES_KEYS.seven: {
        return (
          <>
            <div
              translate='no'
              className={classNames(
                classes.rootProductPriceContainer,
                classes.rootProductPriceContainerSec,
                customClassName,
                {
                  [classes.rootProductPriceContainerMobileSec]: isMobilePreview,
                }
              )}
            >
              {isFree ? (
                freeContainer
              ) : (
                <>
                  <div className={classNames(classes.rootProductPrice)}>
                    {oldPrice ? <div className={classes.rootProductPriceOld}>{oldPriceValue}</div> : null}
                    <div className={classNames(classes.rootProductPriceNew, classes.rootProductPriceNewSec)}>
                      {priceValue}
                    </div>
                    <div className={classes.rootProductPriceNetto}>
                      {useNetPrice && <i>{I18n.t('react.shared.netto')}</i>}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )
      }
      case ORDER_BUMPS_VIEW_TYPES_KEYS.fifth: {
        return (
          <>
            <div
              translate='no'
              className={classNames(
                classes.rootProductPriceContainer,
                classes.rootProductPriceContainerSec,
                customClassName,
                {
                  [classes.rootProductPriceContainerMobileSec]: isMobilePreview,
                }
              )}
            >
              {isFree ? (
                freeContainer
              ) : (
                <>
                  <div className={classNames(classes.rootProductPrice)}>
                    {oldPrice ? <div className={classes.rootProductPriceOld}>{oldPriceValue}</div> : null}
                    <div className={classNames(classes.rootProductPriceNew, classes.rootProductPriceNewSec)}>
                      {priceValue}
                    </div>
                    <div className={classes.rootProductPriceNetto}>
                      {useNetPrice && <i>{I18n.t('react.shared.netto')}</i>}
                    </div>
                  </div>
                  <div className={classes.rootProductPriceType}>{this.getPricingPlanName(form)}</div>
                </>
              )}
            </div>
            {showSelectPricingPlanButton ? (
              <div
                className={classNames(classes.pricingPlanWrapperMobile, {
                  [classes.rootProductPriceContainerMobileFifth]: isMobilePreview,
                })}
              >
                {this.renderChangePaymentPlanButton(indexKey, 0, customClassName)}
              </div>
            ) : null}
          </>
        )
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.fourth: {
        return (
          <>
            <div
              translate='no'
              className={classNames(
                classes.rootProductPriceContainer,
                classes.rootProductPriceContainerFourth,
                classes.rootProductPriceContainerSec,
                customClassName,
                {
                  [classes.rootProductPriceContainerMobileSec]: isMobilePreview,
                }
              )}
            >
              {isFree ? (
                freeContainer
              ) : (
                <>
                  <div className={classNames(classes.rootProductPrice, classes.rootProductPriceSec)}>
                    <div className={classNames(classes.rootProductPriceNew, classes.rootProductPriceNewSec)}>
                      {priceValue}
                    </div>
                    <div className={classes.rootProductPriceNetto}>
                      {useNetPrice && <i>{I18n.t('react.shared.netto')}</i>}
                    </div>
                    <div className={classes.rootProductPriceType}>{this.getPricingPlanName(form)}</div>
                  </div>
                  {oldPrice ? (
                    <div className={classNames(classes.rootProductPriceOld, classes.rootFloatingProductPriceOldSec)}>
                      {oldPriceValue}
                    </div>
                  ) : null}
                </>
              )}
            </div>
            {showSelectPricingPlanButton ? (
              <div
                className={classNames(classes.pricingPlanWrapperMobile, {
                  [classes.rootProductPriceContainerMobileFourth]: isMobilePreview,
                })}
              >
                {this.renderChangePaymentPlanButton(indexKey, 0, customClassName)}
              </div>
            ) : null}
          </>
        )
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.third: {
        return (
          <>
            <div
              translate='no'
              className={classNames(classes.rootProductPriceContainer, classes.rootProductPriceContainerThird, {
                [classes.rootProductPriceContainerMobile]: isMobilePreview,
                [classes.rootProductPriceContainerMobileThird]: isMobilePreview,
              })}
            >
              {isFree ? (
                freeContainer
              ) : (
                <>
                  <div className={classes.rootProductPrice}>
                    {oldPrice ? <div className={classes.rootProductPriceOld}>{oldPriceValue}</div> : null}
                    <div className={classes.rootPriceWithPlansWrapper}>
                      <div className={classes.rootProductPriceNew}>{priceValue}</div>
                      <div className={classes.rootProductPriceNetto}>
                        {useNetPrice && <i>{I18n.t('react.shared.netto')}</i>}
                      </div>
                    </div>
                    <div className={classes.rootProductPriceType}>{this.getPricingPlanName(form)}</div>
                  </div>
                  {oldPrice ? (
                    <div className={classNames(classes.rootProductPriceOld, classes.rootFloatingProductPriceOld)}>
                      {oldPriceValue}
                    </div>
                  ) : null}
                  {showSelectPricingPlanButton ? (
                    <div className={classes.pricingPlanWrapperDesktop}>
                      {this.renderChangePaymentPlanButton(indexKey, 0, customClassName)}
                    </div>
                  ) : null}
                </>
              )}
            </div>
            {showSelectPricingPlanButton ? (
              <div
                className={classNames(classes.pricingPlanWrapperMobile, {
                  [classes.rootProductPriceContainerMobileThird]: isMobilePreview,
                })}
              >
                {this.renderChangePaymentPlanButton(indexKey, 1, customClassName)}
              </div>
            ) : null}
          </>
        )
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.sec: {
        return (
          <>
            <div
              translate='no'
              className={classNames(
                classes.rootProductPriceContainer,
                classes.rootProductPriceContainerSec,
                classes.rootProductPriceContainerWrapped,
                customClassName,
                {
                  [classes.rootProductPriceContainerMobileSec]: isMobilePreview,
                }
              )}
            >
              {isFree ? (
                freeContainer
              ) : (
                <>
                  <div className={classNames(classes.rootProductPrice, classes.rootProductPriceSec)}>
                    <div className={classNames(classes.rootProductPriceNew, classes.rootProductPriceNewSec)}>
                      {priceValue}
                    </div>
                    <div className={classes.rootProductPriceNetto}>
                      {useNetPrice && <i>{I18n.t('react.shared.netto')}</i>}
                    </div>
                    <div className={classes.rootProductPriceType}>{this.getPricingPlanName(form)}</div>
                  </div>
                  {oldPrice ? (
                    <div className={classNames(classes.rootProductPriceOld, classes.rootFloatingProductPriceOldSec)}>
                      {oldPriceValue}
                    </div>
                  ) : null}
                  {showSelectPricingPlanButton ? (
                    <div className={classes.pricingPlanWrapperDesktop}>
                      {this.renderChangePaymentPlanButton(indexKey, 0, customClassName)}
                    </div>
                  ) : null}
                </>
              )}
            </div>
            {showSelectPricingPlanButton ? (
              <div
                className={classNames(classes.pricingPlanWrapperMobile, {
                  [classes.rootProductPriceContainerMobileSec]: isMobilePreview,
                })}
              >
                {this.renderChangePaymentPlanButton(indexKey, 1, customClassName)}
              </div>
            ) : null}
          </>
        )
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.first:
      default: {
        return (
          <>
            <div
              translate='no'
              className={classNames(classes.rootProductPriceContainerFirst, classes.rootProductPriceContainer, {
                [classes.rootProductPriceContainerMobile]: isMobilePreview,
                [classes.rootProductPriceContainerMobileFirst]: isMobilePreview,
              })}
            >
              {isFree ? (
                freeContainer
              ) : (
                <>
                  <div className={classes.rootProductPrice}>
                    {oldPrice ? <div className={classes.rootProductPriceOld}>{oldPriceValue}</div> : null}
                    <div className={classes.rootPriceWithPlansWrapper}>
                      <div className={classes.rootProductPriceNew}>{priceValue}</div>
                      <div className={classes.rootProductPriceNetto}>
                        {useNetPrice && <i>{I18n.t('react.shared.netto')}</i>}
                      </div>
                      {showSelectPricingPlanButton ? (
                        <div className={classes.pricingPlanWrapperDesktop}>
                          {this.renderChangePaymentPlanButton(indexKey, 0, customClassName)}
                        </div>
                      ) : null}
                    </div>
                    <div className={classes.rootProductPriceType}>{this.getPricingPlanName(form)}</div>
                  </div>
                  {oldPrice ? (
                    <div className={classNames(classes.rootProductPriceOld, classes.rootFloatingProductPriceOld)}>
                      {oldPriceValue}
                    </div>
                  ) : null}
                </>
              )}
            </div>
            {showSelectPricingPlanButton ? (
              <div
                className={classNames(classes.pricingPlanWrapperMobile, {
                  [classes.rootProductPriceContainerMobileFirst]: isMobilePreview,
                })}
              >
                {this.renderChangePaymentPlanButton(indexKey, 1, customClassName)}
              </div>
            ) : null}
          </>
        )
      }
    }
  }

  renderViews = () => {
    const { classes, preview, config, isMobilePreview, showSelectPricingPlanButton, productItem } = this.props
    const backgroundImageSrc = config[ORDER_BUMPS_TABS_KEYS.background][backgroundImage]
    const indexKey = 'view'

    switch (config[ORDER_BUMPS_VIEW_FIELD_KEY]) {
      case ORDER_BUMPS_VIEW_TYPES_KEYS.seven: {
        return (
          <>
            {this.renderBackgroundComposition()}

            <div className={classNames(classes.rootSevenView, { [classes.rootSevenViewMobile]: isMobilePreview })}>
              <div className={classes.rootSevenLeft}>
                <div className={classes.rootProductContainer}>
                  {this.renderButton()}
                  <div className={classes.rootProductName}>{productItem?.name}</div>
                </div>
                {isMobilePreview && <div className={classes.rootSevenRight}>{this.renderProductPrice()}</div>}
                <div>{this.renderProductSeven()}</div>
              </div>
              {!isMobilePreview && <div className={classes.rootSevenRight}>{this.renderProductPrice()}</div>}
            </div>

            {this.renderAdditionalText()}
          </>
        )
      }
      case ORDER_BUMPS_VIEW_TYPES_KEYS.fifth: {
        return (
          <>
            {this.renderBackgroundComposition()}

            <div className={classNames(classes.rootFifthView, { [classes.rootFifthViewMobile]: isMobilePreview })}>
              <div className={classes.rootFifthLeft}>
                {this.renderHeader()}
                {this.renderProductFifth()}
              </div>
              <div className={classes.rootFifthRight}>
                {this.renderProductPrice()}
                {this.renderButton()}
                {showSelectPricingPlanButton ? (
                  <div className={classes.pricingPlanWrapperDesktop}>
                    {this.renderChangePaymentPlanButton(indexKey, 0)}
                  </div>
                ) : null}
              </div>
            </div>

            {this.renderAdditionalText()}
          </>
        )
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.fourth: {
        return (
          <>
            {this.renderBackgroundComposition()}

            <div className={classNames(classes.rootFourthView, { [classes.rootFourthViewMobile]: isMobilePreview })}>
              <div className={classes.rootFourthViewImg}>{this.renderProductImage()}</div>
              <div className={classes.rootFourthViewContent}>
                <div className={classes.rootFourthHeaderWrapper}>
                  {this.renderHeader()}
                  {showSelectPricingPlanButton ? (
                    <div className={classes.pricingPlanWrapperDesktop}>
                      {this.renderChangePaymentPlanButton(indexKey, 0)}
                    </div>
                  ) : null}
                </div>

                {this.renderProductFourth()}
              </div>
            </div>
            {this.renderButton()}
            {this.renderAdditionalText()}
          </>
        )
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.third: {
        return (
          <>
            <div className={classes.rootBackground} />
            <div className={classes.rootBackgroundBorder} />
            <div className={classNames(classes.rootThirdViewHeaderRoot)}>{this.renderHeader()}</div>
            <div
              className={classNames(classes.rootThirdViewRoot, { [classes.rootThirdViewRootMobile]: isMobilePreview })}
            >
              <div className={classes.rootThirdViewImgRoot}>
                {backgroundImageSrc ? (
                  <div className={classes.rootThirdViewImgRootImg} />
                ) : (
                  <div className={classNames(classes.viewImageHelp, classes.thirdImageHelp)}>
                    <i className={classNames('fas fa-image', classes.viewImageHelpIcon, classes.thirdImageHelpIcon)} />
                    <div className={classNames(classes.viewImageHelpText, classes.thirdImageHelpText)}>
                      {preview ? I18n.t('react.shared.order_bumps.tabs_background.image_help') : ''}
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.rootThirdViewContentRoot}>
                {this.renderProductThird()}
                {this.renderButton()}
              </div>
            </div>
            {this.renderAdditionalText()}
          </>
        )
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.sec: {
        return (
          <>
            <div className={classes.rootBackground} />
            <div className={classes.rootBackgroundBorder} />
            <div
              className={classNames(classes.rootSecViewMobileHeaderRoot, {
                [classes.rootSecViewMobileHeaderActive]: isMobilePreview,
              })}
            >
              {this.renderHeader()}
            </div>
            <div className={classNames(classes.rootSecViewRoot, { [classes.rootSecViewRootMobile]: isMobilePreview })}>
              <div className={classes.rootSecViewImgRoot}>
                {backgroundImageSrc ? (
                  <div className={classes.rootSecViewImgRootImg} />
                ) : (
                  <div className={classNames(classes.viewImageHelp, classes.secViewImageHelp)}>
                    <i
                      className={classNames('fas fa-image', classes.viewImageHelpIcon, classes.secViewImageHelpIcon)}
                    />
                    <div className={classNames(classes.viewImageHelpText, classes.secViewImageHelpText)}>
                      {preview ? I18n.t('react.shared.order_bumps.tabs_background.image_help') : ''}
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.rootSecViewContentRoot}>
                {this.renderHeader()}
                {this.renderProductSec()}
                {this.renderButton()}
              </div>
            </div>
            {this.renderAdditionalText()}
          </>
        )
      }

      case ORDER_BUMPS_VIEW_TYPES_KEYS.first:
      default: {
        return (
          <>
            {this.renderBackgroundComposition()}
            {this.renderHeader()}
            {this.renderProduct()}
            {this.renderProductPrice()}
            {this.renderButton()}
            {this.renderAdditionalText()}
          </>
        )
      }
    }
  }

  render() {
    const { classes, isMobilePreview, isAllListedType, lastIndex } = this.props

    const rootClasses = classNames(classes.root, {
      [classes.rootMobile]: isMobilePreview,
      [classes.rootNoShadow]: isAllListedType,
    })

    return (
      // TODO: SSR - isMobilePreview var deppen on screen width and sometime cannot be properly rendered on server
      <ClientOnly>
        <div className={rootClasses}>
          {this.renderViews()}
          {isAllListedType && !lastIndex && <div className={classes.sevenHorisontalSeparator} />}
        </div>
      </ClientOnly>
    )
  }
}

/** Order Bump Item with styles */
export const OrderBumpItemWithStyles = withStyles(orderBumpStyles)(OrderBumpItemComponent)

/** Order Bump Item witht Theme Provider */
export class OrderBumpItem extends Component {
  render() {
    const {
      config = {},
      upsell = {},
      productItem = {},
      pricingPlans = [],
      previewMode = '',
      preview = false,
      useRealPricingPlans = true,
      convertToPrice,
      selectionValue,
      selectUpsell = () => {},
      deselectUpsell = () => {},
      ownPlans,
      countries,
      handlePricingPlanChange,
      selectedPricingPlan,
      upsellType = UPSELLS_TYPES.bump,
      isAllListedType,
      lastIndex,
      hidePlanSelection,
    } = this.props

    const getRootProductDescription = () => {
      const isDescriptionActive = config[ORDER_BUMPS_TABS_KEYS.description]?.[productDescriptionActive]
      const sellerDescription = config[ORDER_BUMPS_TABS_KEYS.description]?.[productDescription]

      return isDescriptionActive ? sellerDescription || productItem?.description : productItem?.description
    }

    const isMobilePreview = previewMode === ORDER_BUMPS_PREVIEW_TYPES.mobile
    const isEventInfoProvided = Boolean(upsell?.ticketId)
    const isFree = isEventInfoProvided ? upsell?.ticket?.free && !ownPlans : productItem.free && !ownPlans
    const showSelectPricingPlanButton =
      ((pricingPlans?.length > 1 && !isFree) || (preview && !useRealPricingPlans && !ownPlans)) &&
      config[ORDER_BUMPS_TABS_KEYS.product][priceActive] &&
      (preview || !hidePlanSelection)
    const isDescriptionProvided = Boolean(getRootProductDescription())
    const isHeaderProvided = config[ORDER_BUMPS_TABS_KEYS.header][headerText]

    return (
      <ThemeProvider
        theme={{
          ...config,
          checkboxState: String(selectionValue) === String(upsell.id),
          isMobilePreview,
          isDescriptionProvided,
          isHeaderProvided,
          isEventInfoProvided,
          showSelectPricingPlanButton,
        }}
      >
        <OrderBumpItemWithStyles
          upsell={upsell}
          config={config}
          preview={preview}
          isMobilePreview={isMobilePreview}
          previewMode={previewMode}
          productItem={productItem}
          selectUpsell={selectUpsell}
          deselectUpsell={deselectUpsell}
          selectionValue={selectionValue}
          selectedPricingPlan={selectedPricingPlan && selectedPricingPlan.id ? selectedPricingPlan : pricingPlans[0]}
          pricingPlans={pricingPlans}
          showSelectPricingPlanButton={showSelectPricingPlanButton}
          convertToPrice={convertToPrice}
          isFree={isFree}
          ownPlans={ownPlans}
          countries={countries}
          handlePricingPlanChange={handlePricingPlanChange}
          upsellType={upsellType}
          isAllListedType={isAllListedType}
          lastIndex={lastIndex}
          rootProductDescription={getRootProductDescription()}
        />
      </ThemeProvider>
    )
  }
}

export default OrderBumpItem
