import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { EMBED_TYPES } from 'constants/embeddableItemsShared.constants'
import { ORDER_BUMPS_POSITIONS_TYPES, PAYMENT_PAGE_LAYOUT } from 'constants/paymentPageTemplates.constants'

import { useShopStores } from 'shop/hooks/use-store'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

import { OneClickPayment } from 'shop/components/payment/new/OneClickPayment'

import { Purchase } from '../purchase'
import { Summary } from '../summary'
import { Upsells } from '../upsells'
import { PayerForms } from '../payerForms'
import { ShopHeader } from '../header'
import { Tickets } from '../tickets'
import { Attendees } from '../attendees'
import { InvoiceBanner } from '../invoice-banner/InvoiceBanner'
import { TermsAndBuyButton } from '../TermsAndBuyButton'
import { SessionTimer } from '../session-timer/SessionBasketTimer'
import { SocialProofBubbleContainer } from '../social-proof-bubble/SocialProofBubble'
import { ResellerMessage } from '../reseller-message/ResellerMessage'

interface Props {
  activeUpsellPosition?: string
  orderBumpPreviewMode?: string
  manageLink?: string
  isTemplatePreview?: boolean
  iframeData?: {
    shortDescription: string
    checkoutHtml: string
    checkoutHtmlFooter: string
    pricingPlans: {
      id: number
    }[]
    sessionTimerSettings: {
      isSessionTimerActive: boolean
      timerTime: string
      timerText: string
      timerBackgroundColor: string
      timerFontColor: string
      backgroundImageActive: boolean
      backgroundImage: {
        coverId: string
        coverUrl: string
      }
    }
    free: boolean
  }
  cabinetPreview?: boolean
  themeStore
}

export const Simplified: React.FC<Props> = observer(function Simplified(props) {
  const { activeUpsellPosition, orderBumpPreviewMode, isTemplatePreview, manageLink, iframeData, themeStore } = props
  const { paymentStore, userStore } = useShopStores()

  const isModalEmbed = paymentStore.store?.props?.embedType === EMBED_TYPES.modal
  const wrapperClasses = classNames({
    'embeded-page-wrapper': isModalEmbed,
  })

  return (
    <div className={wrapperClasses}>
      <SessionTimer
        timer={iframeData?.sessionTimerSettings || paymentStore.product?.sessionTimerSettings}
        productId={paymentStore.product?.id}
        userId={userStore.item?.id}
      />

      <div
        className='fr-view'
        dangerouslySetInnerHTML={{
          __html: cleanupHTMLStyleFonts(iframeData?.checkoutHtml || paymentStore.product?.checkoutHtml) || '',
        }}
      />

      {paymentStore.invoice?.token && <InvoiceBanner order={paymentStore.buildedOrder} manageLink={manageLink} />}

      <ShopHeader isTemplatePreview={isTemplatePreview} iframeData={iframeData} />

      <Tickets />

      <Upsells
        upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowProductDetails}
        activeLayout={PAYMENT_PAGE_LAYOUT.simplified}
        activeUpsellPosition={activeUpsellPosition}
        previewMode={orderBumpPreviewMode}
      />

      {paymentStore.isOneClickPaymentAvailable && (
        <div className='widget-wrap mb-4'>
          <div className='widget-box'>
            <div className='custom-col-12'>
              <OneClickPayment />
            </div>
          </div>
        </div>
      )}

      <div className='widget-wrap mb-4'>
        <div className='widget-box'>
          <div className='custom-col-12'>
            <PayerForms isTemplatePreview={isTemplatePreview} />
            {paymentStore.proposeAttendees && (
              <Fragment>
                <div className='widget-line' />
                <Attendees />
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <Upsells
        upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails}
        activeLayout={PAYMENT_PAGE_LAYOUT.simplified}
        activeUpsellPosition={activeUpsellPosition}
        previewMode={orderBumpPreviewMode}
      />

      <div className='widget-wrap'>
        <div className='widget-box'>
          <div className='custom-col-12'>
            <Purchase withWidgetLine withoutTermsAndBuy iframeFree={iframeData?.free} themeStore={themeStore} />
            <Summary isTemplatePreview={isTemplatePreview} iframeFree={iframeData?.free} />
            <div className='purchase'>
              <TermsAndBuyButton
                iframeFree={iframeData?.free}
                upsellItems={
                  <Upsells
                    upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods}
                    activeLayout={PAYMENT_PAGE_LAYOUT.simplified}
                    activeUpsellPosition={activeUpsellPosition}
                    previewMode={orderBumpPreviewMode}
                  />
                }
              />
            </div>

            <ResellerMessage />
          </div>
        </div>
      </div>

      <div
        className='fr-view'
        dangerouslySetInnerHTML={{
          __html:
            cleanupHTMLStyleFonts(iframeData?.checkoutHtmlFooter || paymentStore.product?.checkoutHtmlFooter) || '',
        }}
      />

      {paymentStore.product.socialProofBubbleActivated && (
        <SocialProofBubbleContainer bubbleData={paymentStore.product.socialProofBubble} />
      )}
    </div>
  )
})
