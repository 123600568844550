import React from 'react'
import { EloIconProps } from '../types'

export const EloKlarnaIcon: React.FC<EloIconProps> = ({ width = 46, height = 40 }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 100 40.4494'>
    <defs>
      <style>{'.cls-1 {fill: #ffb3c7;}'}</style>
    </defs>
    <g id='Lager_2' data-name='Lager 2'>
      <g id='Layer_1' data-name='Layer 1'>
        <rect className='cls-1' width='100' height='40.4494' rx='11.7384' />
        <path d='M80.0772,17.7235a6.194,6.194,0,1,0,0,10.2373v.75h3.5145V16.9727H80.0772Zm-3.1964,8.1388a3.0243,3.0243,0,1,1,3.1849-3.02A3.1059,3.1059,0,0,1,76.8808,25.8623Z' />
        <rect x='28.2027' y='11.7416' width='3.678' height='16.9694' />
        <path d='M64.1542,16.6569a4.2694,4.2694,0,0,0-3.62,1.6383V16.9733h-3.5V28.711h3.5421V22.5424a2.4754,2.4754,0,0,1,2.6383-2.6591c1.5447,0,2.4325.9228,2.4325,2.6348V28.711h3.5107V21.2464C69.1577,18.5146,66.9857,16.6569,64.1542,16.6569Z' />
        <path d='M43,17.7235a6.194,6.194,0,1,0,0,10.2373v.75h3.5145V16.9727H43Zm-3.1965,8.1388a3.0243,3.0243,0,1,1,3.1845-3.02A3.1058,3.1058,0,0,1,39.8034,25.8623Z' />
        <path d='M51.9513,18.5017V16.9728H48.353V28.7111h3.606v-5.48c0-1.8491,2.0042-2.843,3.3949-2.843.014,0,.0276.0014.0417.0015V16.9735A4.3821,4.3821,0,0,0,51.9513,18.5017Z' />
        <path d='M87.323,24.5476a2.2059,2.2059,0,1,0,2.206,2.2059A2.2057,2.2057,0,0,0,87.323,24.5476Z' />
        <path d='M25.6675,11.7384H21.8558a9.7488,9.7488,0,0,1-3.9412,7.8678l-1.51,1.131,5.8513,7.9792h4.8106l-5.3837-7.342A13.5049,13.5049,0,0,0,25.6675,11.7384Z' />
        <rect x='12.1204' y='11.7384' width='3.8185' height='16.9773' />
      </g>
    </g>
  </svg>
)
