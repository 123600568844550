import React from 'react'
import { observer } from 'mobx-react'

import { useShopStores } from 'shop/hooks/use-store'

import './reseller-message.scss'

export const ResellerMessage = observer(() => {
  const { sellerStore } = useShopStores()

  if (!sellerStore.item.powerSeller) {
    return
  }

  const isNamottoUniversalMessage = sellerStore.item.resellerId === 3 || sellerStore.item.resellerId === 4
  const isNamottoAcademiesMessage = sellerStore.item.resellerId === 5

  const showResellerMessage = isNamottoUniversalMessage || isNamottoAcademiesMessage

  return (
    showResellerMessage && (
      <div className='checkout-reseller-message'>
        {isNamottoUniversalMessage && (
          <div dangerouslySetInnerHTML={{ __html: I18n.t('react.shop.payment.namotto_universal_message') }} />
        )}
        {isNamottoAcademiesMessage && (
          <div dangerouslySetInnerHTML={{ __html: I18n.t('react.shop.payment.namotto_academies_message') }} />
        )}
      </div>
    )
  )
})
