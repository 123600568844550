import React from 'react'
import { convert } from 'html-to-text'

interface ProductSchemaProps {
  productName: string
  productImage: string
  productDescription: string
  tickets: {
    ticketDates: {
      startDate: string
      endDate: string
    }[]
    locationAddress: string
    countryCode: string
    ticketName: string
    isOnline: boolean
    plans: {
      productPrice: string | number
      productCurrency: string
    }[]
  }[]
}

export const EventSchema: React.FC<ProductSchemaProps> = ({
  productName,
  productImage,
  productDescription,
  tickets,
}) => {
  if (!productName) {
    return null
  }
  const schemaJsonObj = []

  tickets.map((ticket) => {
    if (ticket.ticketDates?.length < 1) {
      return null
    }

    ticket.ticketDates.map(({ startDate, endDate }) => {
      if (!startDate) {
        return null
      }

      const event: any = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: productName,
        eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
        eventStatus: 'https://schema.org/EventScheduled',
        offers: ticket.plans.map((plan) => ({
          '@type': 'Offer',
          price: plan.productPrice,
          priceCurrency: plan.productCurrency.toUpperCase(),
          availability: 'https://schema.org/InStock',
        })),
      }

      if (startDate) {
        event.startDate = startDate
      }

      if (endDate) {
        event.endDate = endDate
      }

      if (productImage) {
        event.image = productImage
      }

      if (productDescription) {
        event.description = convert(productDescription, {
          selectors: [
            { selector: 'img', format: 'skip' },
            { selector: 'a', options: { ignoreHref: true } },
          ],
        })
      }

      event.location = ticket.isOnline
        ? {
            '@type': 'VirtualLocation',
          }
        : {
            '@type': 'Place',
            name: ticket.ticketName,
            address: {
              '@type': 'PostalAddress',
              addressRegion: ticket.countryCode,
              streetAddress: ticket.locationAddress,
              contactType: 'sales',
            },
          }

      schemaJsonObj.push(event)
    })
  })

  return <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaJsonObj) }} />
}
